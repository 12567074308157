import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import { auth, db } from "../../firebase";
import {
  query,
  collection,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import { API_URL } from "../../config";
import axios from "axios";
import { useUserDispatch, signOut } from "../../context/UserContext";
export default function Settings(props) {
  var userDispatch = useUserDispatch();
  const [Isloading, setIsloading] = useState(false);
  const [datatableData, setDatatableData] = useState([]);
  React.useEffect(() => {
    try {
      const q = query(
        collection(db, "shops"),
        where("uid", "==", auth?.currentUser?.uid),
      );
      getDocs(q).then((res) => {
        const data = res.docs.map((item) => {
          var data = {
            id: item.id,
            clientSecret: item.data().clientSecret,
            clinetId: item.data().clinetId,
            plateform: item.data().plateform,
            shopName: item.data().shopName,
            status: item.data().status,
            uid: item.data().uid,
            access_token: item.data().access_token,
            refresh_token: item.data().refresh_token,
            productionOAuth: item.data().productionOAuth,
          };

          return data;
        });
        setDatatableData(data);
      });
    } catch (err) {
      console.error(err.message);
    }
  }, []);

  const notify = (mgs, type) =>
    toast(mgs, {
      position: "top-right",
      theme: "light",
      type: type,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const refreshtokenfromebay = async () => {
    setIsloading(true);
    var request = [];

    try {
      datatableData.forEach((element) => {
        let temprequest = axios.post(`${API_URL}/refreshtoken`, {
          refresh_token: element.refresh_token,
          clientId: "Iftikhar-oneclic-PRD-03a3d0a07-40921ebf",
          clientSecret: "PRD-3a3d0a07e658-ef78-4284-a0ad-0dd2",
        });
        request.push(temprequest);
      });

      let response = await axios.all(request);

      if (response) {
        console.log("response", response);
        response.map(async (item, index) => {
          const { access_token } = item.data;
          const docRef = doc(db, "shops", datatableData[index].id);

          await updateDoc(docRef, {
            access_token: access_token.access_token,
          });
        });

        setIsloading(false);
        notify("Access Token Updated", "success");
        setTimeout(() => {
          window.localStorage.clear();
          signOut(userDispatch, props.history);
        }, 3000);
      }
    } catch (error) {
      setIsloading(false);
      notify("Something went wroung!!", "error");
      console.log(error);
    }
  };

  return (
    <>
      <PageTitle title="Refresh Access Token" />

      {datatableData.length !== 0 ? (
        <>
          {datatableData.map((item) => {
            return (
              <>
                <div className="container rounded bg-white mt-5 mb-5">
                  <div className="col-md-10 border-right">
                    <div className="p-3 py-5">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="text-right">{item.shopName}</h4>
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Client Secret</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="first name"
                          value={item.clientSecret}
                          disabled
                        />
                      </div>
                      <br />
                      <div className="col-md-12">
                        <label className="labels">Client Id</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="enter email id"
                          value={item.clinetId}
                          disabled
                        />
                      </div>
                      <br />
                      <div className="col-md-12">
                        <label className="labels">Refresh Token</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="enter phone number"
                          value={item.refresh_token}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          <div className="row">
            {Isloading ? (
              <div className="mt-5 text-center">
                <CircularProgress size={26} />
              </div>
            ) : (
              <div className="mt-5 text-center">
                <button
                  className="btn btn-primary profile-button"
                  onClick={() => {
                    refreshtokenfromebay();
                  }}
                  type="button"
                >
                  Refresh Token
                </button>
              </div>
            )}
          </div>
        </>
      ) : null}
    </>
  );
}
