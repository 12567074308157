import React, { useEffect, useState } from 'react'
import queryString from 'query-string';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  CircularProgress

} from "@material-ui/core";
import { Link } from 'react-router-dom';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { db } from '../../firebase';
import {
  updateDoc,
  doc

} from "firebase/firestore";
import { API_URL } from '../../config';
function GetAcessToken(props) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const query = queryString.parse(props.location.search);
    const shopId = query.shopId;
    if (shopId) {
      axios.get(`${API_URL}/getaccesstoken`).then(
        (res) => {
          const { accesstoken } = res.data;
          const { access_token, refresh_token } = accesstoken;

          window.localStorage.setItem('access_token', access_token)
          window.localStorage.setItem('refresh_token', refresh_token)

          updateintegrationstatusandaccesstoken(shopId, access_token, refresh_token)
        }
      ).catch((err) => {
        console.log(err)
      })
    }

  }, [props.location.search])


  const updateintegrationstatusandaccesstoken = async (shopId, accesstoken, refreshtoken) => {

    const docRef = doc(db, "shops", shopId);
    updateDoc(docRef, {
      "status": 'Active',
      "access_token": accesstoken,
      "refresh_token": refreshtoken,
    }).then(() => {
      setIsLoading(false);
    })




  }

  return (
    <div>
      {
        isLoading ?
          <>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </>
          :
          <Box sx={{ width: '100%' }}>
            <Typography variant="h1" component="h2">
              Your Access is Granted!!
            </Typography>
            <Link to='/app/dashboard'>

              <Button variant="contained" color="success">
                <KeyboardReturnIcon />
              </Button>
            </Link>
          </Box>

      }


    </div>
  )
}

export default GetAcessToken