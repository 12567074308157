import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Tooltip,
  Button,
  CircularProgress,
  Modal,
  Box,
  Card,
  CardContent,
} from "@material-ui/core";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import EditIcon from "@material-ui/icons/Edit";
import { API_URL } from "../../config";
import { useSelector } from "react-redux";
function LiveProducts() {
  const [messages, setMessages] = useState([]);
  const [openmodel, setOpenModel] = React.useState(false);
  const handleOpenModel = () => setOpenModel(true);
  const handleCloseModel = () => setOpenModel(false);

  const [seletecOrder, setSeletecOrder] = useState({});
  const [selectedproductoffer, setSelectedproductoffer] = useState({});
  const [isdataloading, setIsdataloading] = useState(false);
  const { shop } = useSelector((state) => state.selectshoptoken);

  const [isloading, setIsloading] = useState(false);
  const [openeditmodel, setOpeneditModel] = React.useState(false);
  const handleOpenEditModel = () => setOpeneditModel(true);
  const handleCloseEditModel = () => setOpeneditModel(false);

  const [sku, setSku] = useState("");
  const [quantity, setQuantity] = useState("");

  useEffect(() => {
    setIsdataloading(true);
    setMessages([]);
    const { access_token } = shop;
    axios
      .post(`${API_URL}/getinventory`, {
        token: access_token,
        limit: "200",
        offset: "0",
        ordertype: "shipped",
      })
      .then((response) => {
        const { data } = response;
        const { inventoryItems } = data.data;
        console.log("inventoryItems", inventoryItems);
        const shippeddata = inventoryItems.map((item, index) => {
          console.log(item);
          const { sku, product, availability } = item;
          const { title, imageUrls } = product;
          const { shipToLocationAvailability } = availability;
          const { quantity } = shipToLocationAvailability;
          var data = {
            sku: sku,
            title: title,
            quantity: quantity,
            imageUrl: imageUrls ? imageUrls[0] : [],
            imageUrls: imageUrls,
          };
          return data;
        });

        console.log(shippeddata);
        setMessages(shippeddata);
        setIsdataloading(false);
      })
      .catch(function (error) {
        setIsdataloading(false);
        console.log(error);
      });
  }, [shop]);

  const columns = [
    {
      name: "imageUrl",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <img src={value} alt="product img" width={100} height={100} />
            </>
          );
        },
      },
    },
    {
      name: "sku",
      options: {
        filter: false,
      },
    },
    {
      name: "title",
      options: {
        filter: false,
      },
    },

    {
      name: "quantity",
      options: {
        filter: false,
      },
    },

    {
      name: "View Details",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;
          return (
            <>
              <Tooltip title="View Details">
                <Button className="mx-2">
                  <OpenInNewIcon
                    style={{ color: "blue" }}
                    onClick={() => {
                      const { access_token } = shop;

                      messages.forEach((item) => {
                        if (item.sku === rowData[1]) {
                          setSeletecOrder(item);
                        }
                      });

                      axios
                        .post(`${API_URL}/getitem`, {
                          token: access_token,
                          sku: rowData[1],
                        })
                        .then((res) => {
                          const { data } = res.data;
                          console.log(data);
                          const { offers } = data;
                          const {
                            categoryId,
                            listingDescription,
                            status,
                            listing,
                            pricingSummary,
                            format,
                            marketplaceId,
                            offerId,
                          } = offers[0];
                          const { listingId } = listing;
                          const { price } = pricingSummary;
                          const { value } = price;
                          setSelectedproductoffer({
                            categoryId: categoryId,
                            listingDescription: listingDescription,
                            status: status,
                            listingId: listingId,
                            cost: value,
                            format: format,
                            marketplaceId: marketplaceId,
                            offerId: offerId,
                          });

                          handleOpenModel();
                        })
                        .catch((err) => {
                          console.log(err.message);
                        });
                    }}
                  />
                </Button>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;
          // var link = `/app/updateshop/${rowData[0]}`
          console.log(value);
          console.log(rowData);
          return (
            <>
              <Tooltip title="Edit">
                <Button className="mx-2">
                  <EditIcon
                    style={{ color: "blue" }}
                    onClick={() => {
                      setSku(rowData[1]);
                      setQuantity(rowData[3]);
                      handleOpenEditModel(true);
                      console.log(value);
                      console.log(rowData);
                    }}
                  />
                </Button>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: "70%",
      height: "90%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: "none",
      overflowY: "scroll",
    },
  }));
  const classes = useStyles();
  const modelstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    boxShadow: 24,
    p: 4,
  };
  const editmodelstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
    boxShadow: 24,
    p: 4,
  };

  const notify = (mgs, type) =>
    toast(mgs, {
      position: "top-right",
      theme: "light",
      type: type,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const updatequantity = () => {
    try {
      const { access_token } = shop;
      setIsloading(true);

      axios
        .post(`${API_URL}/reviseinventorystatus`, {
          ItemID: "",
          SKU: sku,
          Quantity: quantity,
          token: access_token,
        })
        .then((res) => {
          console.log(res);
          setIsloading(false);
          notify("Record Updated", "success");
        })
        .catch((err) => {
          console.log(err);
          notify("Something went wroung!!", "error");
          setIsloading(false);
        });
    } catch (error) {}
  };

  return (
    <>
      <PageTitle title="All Prodcuts" />
      <Modal
        open={openmodel}
        onClose={handleCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={modelstyle} className={classes.paper}>
          <div>
            <h3>Product Numbers {seletecOrder.sku}</h3>
          </div>

          <div className="row">
            <Box className={classes.root}>
              <Card>
                <CardContent>
                  <div className="row">
                    <div className="col-2">
                      {" "}
                      <h6>Title</h6>
                    </div>
                    <div className="col-10">
                      <p>{seletecOrder.title}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      {" "}
                      <h6>Quantity</h6>
                    </div>
                    <div className="col-10">
                      <p>{seletecOrder.quantity}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      {" "}
                      <h6>Format</h6>
                    </div>
                    <div className="col-10">
                      <p>{selectedproductoffer.format}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      {" "}
                      <h6>Status</h6>
                    </div>
                    <div className="col-10">
                      <p>{selectedproductoffer.status}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      {" "}
                      <h6>Listing Id</h6>
                    </div>
                    <div className="col-10">
                      <p>{selectedproductoffer.listingId}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      {" "}
                      <h6>Cost</h6>
                    </div>
                    <div className="col-10">
                      <p>£ {selectedproductoffer.cost}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      {" "}
                      <h6>Marketplace Id</h6>
                    </div>
                    <div className="col-10">
                      <p>{selectedproductoffer.marketplaceId}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      {" "}
                      <h6>Category Id</h6>
                    </div>
                    <div className="col-10">
                      <p>{selectedproductoffer.categoryId}</p>
                    </div>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedproductoffer.listingDescription,
                    }}
                  ></div>
                  <div></div>
                  {seletecOrder.imageUrls !== undefined ? (
                    <>
                      <div className="d-flex flex-row justify-content-evenly overflow-scroll  scoll-pane">
                        {seletecOrder.imageUrls.map((hero, index) => {
                          return (
                            <div key={index} className="col-md-3 mx-1">
                              <img
                                className="rounded"
                                src={hero}
                                alt={index}
                                width="210px"
                                height="300px"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                </CardContent>
              </Card>
            </Box>
          </div>
          <br />
        </Box>
      </Modal>

      <Modal
        open={openeditmodel}
        onClose={handleCloseEditModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={editmodelstyle} className={classes.paper}>
          <div>
            <h3>Edit Sku</h3>
          </div>

          <div className="row">
            <Box className={classes.root}>
              <Card>
                <CardContent>
                  <div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        value={sku}
                        onChange={(event) => {
                          setSku(event.target.value);
                        }}
                        class="form-control"
                        id="floatingInput"
                        placeholder="SkU"
                      />
                      <label>SkU</label>
                    </div>
                    <br />
                    <div className="form-floating">
                      <input
                        type="text"
                        value={quantity}
                        onChange={(event) => {
                          setQuantity(event.target.value);
                        }}
                        class="form-control"
                        id="floatingInput"
                        placeholder="Quantity"
                      />
                      <label>Quantity</label>
                    </div>
                    <br />

                    {isloading ? (
                      <div className="mt-5 text-center">
                        <CircularProgress size={26} />
                      </div>
                    ) : (
                      <button
                        type="primary"
                        onClick={() => {
                          updatequantity();
                        }}
                        className="btn btn-success float-end my-3"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Box>
          </div>
          <br />
          <br />
        </Box>
      </Modal>

      <Grid item xs={12}>
        {isdataloading ? (
          <div className="my-5 text-center">
            <CircularProgress size={25} />
            <span className="fs-3 fw-bold mx-3"> loading...</span>
          </div>
        ) : (
          <MUIDataTable
            title="Shops List"
            data={messages}
            columns={columns}
            options={{
              sort: true,
              selectableRows: "none",
            }}
          />
        )}
      </Grid>
    </>
  );
}

export default LiveProducts;
