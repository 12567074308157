import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  ArrowBack as ArrowBackIcon,
  Email as EmailIcon,
  LocalShipping as LocalShippingIcon,
} from "@material-ui/icons";

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import SettingsIcon from "@material-ui/icons/Settings";
import ListIcon from "@material-ui/icons/List";
import CategoryIcon from "@material-ui/icons/Category";

// import ListIcon from "@mui/icons-material/List";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
  },
  {
    id: 1,
    label: "Products",
    link: "/app/typography",
    icon: <CategoryIcon />,
    children: [
      { label: "Add Listing", link: "/app/addlisting" },
      { label: "Draft Produts", link: "/app/products" },
      { label: "Live Produts", link: "/app/liveprod" },
    ],
  },
  {
    id: 2,
    label: "Shops",
    link: "/app/shops",
    icon: <TableIcon />,
    children: [
      { label: "Add New", link: "/app/addshop" },
      { label: "My Shops", link: "/app/shops" },
    ],
  },
  {
    id: 3,
    label: "Supplier",
    link: "/app/suppliers",
    icon: <NotificationsIcon />,
    children: [
      { label: "Add New", link: "/app/addsupp" },
      { label: "My Suppliers", link: "/app/suppliers" },
    ],
  },
  {
    id: 4,
    label: "Shipping",
    link: "/app/ui",
    icon: <UIElementsIcon />,
    children: [
      { label: "Shipped", link: "/app/shipped" },
      { label: "UnShipped", link: "/app/unshipped" },
      { label: "Royal Mail", link: "/app/royalmailorders" },
    ],
  },
  { id: 5, label: "Message", link: "/app/notifications", icon: <EmailIcon /> },
  {
    id: 6,
    label: "Shipper",
    link: "/app/shipper",
    icon: <LocalShippingIcon />,
  },
  {
    id: 7,
    label: "Stock List",
    link: "/app/stocklist",
    icon: <ListIcon />,
  },
  {
    id: 8,
    label: "Settings",
    link: "/app/Settings",
    icon: <SettingsIcon />,
  },
  { id: 9, type: "divider" },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
