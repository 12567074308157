import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,

} from "@material-ui/core";
import { Link } from 'react-router-dom';
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import SelectedShop from '../selectshop/selectedshop';
// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';




export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [profileMenu, setProfileMenu] = useState(null);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          ONECLICK
        </Typography>
        <div className={classes.grow} />


        <SelectedShop />




        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {

          }}
          className={classes.headerMenuButton}
        >
          <Link to='/app/notifications'>
            <Tooltip title="Message">
              <MailIcon classes={{ root: classes.headerIcon }} />
            </Tooltip>

          </Link>
        </IconButton>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>




        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >


          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <Link to='/app/profile' style={{
              color: 'grey',
              textDecoration: 'none'
            }}>
              <AccountIcon className={classes.profileMenuIcon} />
              <span>Profile</span>
            </Link>
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >

            <PowerSettingsNewIcon className={classes.profileMenuIcon} />
            <span
              style={{
                color: 'grey',
                textDecoration: 'none'
              }}
              onClick={() => {
                window.localStorage.removeItem('access_token')
                window.localStorage.removeItem('refresh_token')
                window.localStorage.clear();
                signOut(userDispatch, props.history)
              }
              }
            >
              Sign Out
            </span>

          </MenuItem>


          <div className={classes.profileMenuUser}>

          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
