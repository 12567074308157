import React from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";


export default function NotificationsPage(props) {
  var classes = useStyles();
  function CloseButton({ closeToast, className }) {
    return <CloseIcon className={className} onClick={closeToast} />;
  }

  return (
    <>
        <ToastContainer
       
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
    </>
  );

 

}







