import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import logo from "../../images/logo-dark-sm.png";
import { useUserDispatch, SignupUser } from "../../context/UserContext";
import applogo from "../../images/logo-dark.png";
function Signup(props) {
  var classes = useStyles();
  var userDispatch = useUserDispatch();
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [nameValue, setNameValue] = useState("");
  var [emailValue, setEmailValue] = useState("");
  var [phoneValue, setPhoneValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [addrValue, setAddrValue] = useState("");
  // var [statusValue, setStatusValue] = useState("pending");

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>ONE CLICK</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <div className="d-flex justify-content-center">
              <img width="250" height="50" src={applogo} alt="logo" />
            </div>
            <Typography variant="h1" className={classes.greeting}>
              Welcome!
            </Typography>
            <Typography variant="h2" className={classes.subGreeting}>
              Create your account
            </Typography>
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                Something is wrong with your login or password :(
              </Typography>
            </Fade>
            <TextField
              id="name"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={nameValue}
              onChange={(e) => setNameValue(e.target.value)}
              margin="normal"
              placeholder="Full Name"
              type="text"
              fullWidth
            />
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              margin="normal"
              placeholder="Email Adress"
              type="email"
              fullWidth
            />
            <TextField
              id="phone"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={phoneValue}
              onChange={(e) => setPhoneValue(e.target.value)}
              margin="normal"
              placeholder="Phone Number"
              type="text"
              fullWidth
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Password"
              type="password"
              fullWidth
            />
            <TextField
              id="address"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={addrValue}
              onChange={(e) => setAddrValue(e.target.value)}
              margin="normal"
              placeholder="Enter Address"
              type="text"
              fullWidth
            />
            <div className={classes.creatingButtonContainer}>
              {isLoading ? (
                <CircularProgress size={26} />
              ) : (
                <Button
                  onClick={() =>
                    SignupUser(
                      userDispatch,
                      nameValue,
                      emailValue,
                      passwordValue,
                      phoneValue,
                      addrValue,
                      props.history,
                      setIsLoading,
                      setError,
                    )
                  }
                  disabled={
                    emailValue.length === 0 ||
                    passwordValue.length === 0 ||
                    nameValue.length === 0
                  }
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.createAccountButton}
                >
                  Create your account
                </Button>
              )}
            </div>

            <div className="mt-5 text-center">
              already have an account&nbsp;? &nbsp;
              <Link color="primary" size="small" to="/login">
                login
              </Link>
            </div>
            <div className={classes.formDividerContainer}>
              <div className={classes.formDivider} />
              <div className={classes.formDivider} />
            </div>
          </React.Fragment>
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2021-{new Date().getFullYear()}{" "}
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href="https://oneclick.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            One Click
          </a>
          , LLC. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Signup);
