import React, { useEffect, useState } from 'react'
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import {
    Grid,
    Tooltip,
    Button,
    CircularProgress
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import ReplyIcon from '@material-ui/icons/Reply';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import MarkEmailReadIcon from '@material-ui/icons/MarkunreadMailbox';
import FlagIcon from '@material-ui/icons/Flag';
import { API_URL } from '../../config';
import { useSelector } from "react-redux";
function Message() {

    const [messages, setMessages] = useState([]);
    const { shop } = useSelector(state => state.selectshoptoken);
    const [isdataloading, setIsdataloading] = useState(false)


    useEffect(() => {

        setIsdataloading(true)
        const { access_token } = shop;
        axios.post(`${API_URL}/getmessages`, {
            token: access_token
        })
            .then((response) => {
                const { data } = response;
                const { messages } = data;
                const { GetMyMessagesResponse } = messages;
                const { Messages } = GetMyMessagesResponse;
        
                const { Message } = Messages[0];

                const messagedata = Message.map(item => {
                    const { Sender, Subject, ReceiveDate, Read, MessageID, Flagged } = item;

                    var data = {
                        "Id": MessageID[0],
                        "Sender": Sender[0],
                        "Subject": Subject[0],
                        "Flag": Flagged[0],
                        "Received": ReceiveDate[0],
                        "Read": Read[0]
                    }

                    return data;
                })
                setMessages(messagedata)
                setIsdataloading(false)
            })
            .catch(function (error) {
                setIsdataloading(false)
            });

    }, [shop])

    const columns = [
        {
            name: "Sender",
            options: {
                filter: false
            }
        },


        {
            name: "Subject",
            options: {
                filter: false
            }
        },
        {
            name: "Received",
            options: {
                filter: false
            }
        },
        {
            name: "Flag",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const { rowData } = tableMeta;

                    return (
                        <>
                            {
                                rowData[3] === 'true' ?
                                    <Tooltip title="flagged">
                                        <Button className='mx-2'>
                                            <FlagIcon style={{ color: 'green' }} />
                                        </Button>
                                    </Tooltip>
                                    :
                                    <Tooltip title="unFlagged">
                                        <Button className='mx-2' >
                                            <FlagIcon style={{ color: 'light-grey' }} />
                                        </Button>
                                    </Tooltip>
                            }

                        </>
                    );
                }
            }
        },
        {
            name: "Read",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const { rowData } = tableMeta;
                    return (
                        <>
                            {
                                rowData[4] === 'true' ?
                                    <Tooltip title="Read">
                                        <Button className='mx-2'>
                                            <MarkEmailReadIcon style={{ color: 'green' }} />
                                        </Button>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Unread">
                                        <Button className='mx-2' >
                                            <MarkunreadIcon style={{ color: 'red' }} />
                                        </Button>
                                    </Tooltip>
                            }

                        </>
                    );
                }
            }
        },
        {
            name: "Id",
        },
        {
            name: "Reply",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const { rowData } = tableMeta;
                    var link = `https://mesg.ebay.co.uk/mesgweb/ViewMessageDetail/0/m2m/${rowData[5]}`
                    return (
                        <>
                            <Tooltip title="Reply">
                                <Button className='mx-2'>
                                    <ReplyIcon style={{ color: 'blue' }} onClick={() => {
                                        window.open(link, '_blank', 'noopener,noreferrer');
                                    }} />
                                </Button>
                            </Tooltip>
                        </>
                    );
                }
            }
        },
    ];



    return (
        <>

            <div className='row'>
                <div className='col-9'>
                    <div className='fw-bold fs-5'>Customers Message</div>
                </div>

            </div>
            <br /><br />
            
            <Grid item xs={12}>
                {
                    isdataloading ?
                        <div className="my-5 text-center">
                            <CircularProgress size={25} /><span className='fs-3 fw-bold mx-3'> loading...</span>
                        </div>
                        :
                        <MUIDataTable
                            title="Shops List"
                            data={messages}
                            columns={columns}
                            options={{
                                sort: true,
                                selectableRows: 'none',
                            }}
                        />
                }


            </Grid>
        </>

    )



}

export default Message;