import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Button,
  Tooltip
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { db, auth } from '../../firebase';
import { toast } from 'react-toastify';
// components
import {
  Link,
} from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import {
  deleteDoc,
  getDocs,
  doc,
  query,
  collection,
  where
} from "firebase/firestore";


export default function Shops() {

  const [datatableData, setDatatableData] = useState([]);
  const [data, setData] = useState();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [deleteid, setDeleteid] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handledelete = async (e) => {

    e.preventDefault();
    setOpen(false);
    const docRef = doc(db, "shops", deleteid);
    deleteDoc(docRef).then((res) => {
      notify('Shop Deleted successfully!')
      setData([]);
      setOpen(false);
    }).catch((err) => {
      setOpen(false);
    });

  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const notify = (mgs) => toast(mgs, {

    position: "top-right",
    theme: 'light',
    type: "success",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  React.useEffect(() => {
    try {

      const q = query(collection(db, "shops"), where("uid", "==", auth?.currentUser?.uid));
      getDocs(q).then((res) => {
        const data = res.docs.map(item => {
          
          var data = {
            "id": item.id,
            "clientSecret": item.data().clientSecret,
            "clinetId": item.data().clinetId,
            "plateform": item.data().plateform,
            "shopName": item.data().shopName,
            "status": item.data().status,
            "uid": item.data().uid,
            "access_token":item.data().access_token,
            "refresh_token":item.data().refresh_token,
            "productionOAuth":item.data().productionOAuth
          }

          return data;
        })
    
        setDatatableData(data)
      });


    } catch (err) {}

  }, [data])

  const columns = [
    {
      name: "id",
    },
    {
      name: "shopName",
      options: {
        filter: false
      }
    },

    {
      name: "plateform",
      options: {
        filter: true
      }
    },

    {
      name: "status",
      options: {
        filter: false
      }
    },
    {
      name: "clinetId",
      options: {
        filter: true,
      }
    },
    {
      name: "productionOAuth",
      options: {
        customBodyRender:(value, tableMeta, updateValue)=>{
          const { rowData } = tableMeta;

          return (
            <>
              {
                 rowData[3] === 'Active' ?
                 <Tooltip title="Integrate Active">
                   <Button className='mx-2'>
                     <LinkIcon  style={{color:'green'}} />
                   </Button> 
                   </Tooltip>
                   :
                   <Tooltip title="Integrate">
                      <Button onClick={()=>openInNewTab(`${value}&state=${rowData[0]}`)} className='mx-2'>
                     
                     <LinkOffIcon   style={{color:'red'}}   />
                 
                   </Button>
                 </Tooltip>
              }
               
                 


              
          

            </>



          );
        }
      }
    },
    {
      name: "Action",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;
          var link = `/app/updateshop/${rowData[0]}`
          return (
            <>
              <Tooltip title="Edit">
              <Link to={link} >
                <EditIcon className='mx-2' />

              </Link>
              </Tooltip>
              <Tooltip title="Delete">
              <Button className='mx-2'>
                <DeleteIcon onClick={() => {
                  handleClickOpen();
                  setDeleteid(rowData[0]);

                }
                }
                />
              </Button>
              </Tooltip>

            

            </>



          );
        }
      }
    }
  ];

  return (
    <>
      <div className='row'>
                <div className='col-9'>
                    <div className='fw-bold fs-5'>Customers Shops</div>
                </div>
                <div className='col-3'>
                   
                </div>
            </div>
            <br/><br/>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Shop?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete shop?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handledelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <MUIDataTable
          title="Shops List"
          data={datatableData}
          columns={columns}
          options={{
            selectableRows: 'none',
            sort: true,


          }}
        />

      </Grid>
    </>
  );

}

