import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box, IconButton, Link } from "@material-ui/core";
import Icon from "@mdi/react";

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Message from "../../pages/notifications/Message";
import Shipped from "../../pages/shipped";
import UnShipped from "../../pages/unshipped";
import Shops from "../../pages/shops";
import AddShop from "../../pages/addshop/";
import UpdateShop from "../../pages/updateshop";
import UpdateSupplier from "../../pages/updatesupplier";
import Profile from "../../pages/profile";
import AddSupplier from "../../pages/addsupplier";
import Supplier from "../../pages/suppliers";
import GetAcessToken from "../../pages/accesToken";
import Product from "../../pages/products";
import LiveProducts from "../../pages/liveproducts";
import Shipper from "../../pages/shipper";
import ProductDraft from "../../pages/productdist";
import DispatchOrder from "../../pages/dispatchorder";
// context
import { useLayoutState } from "../../context/LayoutContext";
import RoyalMailOrder from "../../pages/royalmail_orders/RoyalMailOrder";
import Settings from "../../pages/settings/settings";
import StockList from "../../pages/stockList/stockList";
import AddEditEbayListing from "../../pages/addEditEbayListing";
function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/notifications" component={Message} />
            <Route path="/app/shipped" component={Shipped} />
            <Route path="/app/unshipped" component={UnShipped} />
            <Route path="/app/shops" component={Shops} />
            <Route path="/app/addshop" component={AddShop} />

            <Route path="/app/products" component={Product} />
            <Route path="/app/liveprod" component={LiveProducts} />
            <Route path="/app/updateshop/:id" component={UpdateShop} />
            <Route path="/app/profile" component={Profile} />
            <Route path="/app/accesToken" component={GetAcessToken} />
            <Route path="/app/royalmailorders" component={RoyalMailOrder} />
            <Route path="/app/Settings" component={Settings} />
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/addsupp" component={AddSupplier} />
            <Route path="/app/suppliers" component={Supplier} />
            <Route path="/app/updatesupp/:id" component={UpdateSupplier} />
            <Route path="/app/shipper" component={Shipper} />
            <Route path="/app/productdraft/:id" component={ProductDraft} />
            <Route path="/app/dispatchorder/:id" component={DispatchOrder} />
            <Route path="/app/stocklist" component={StockList} />
            <Route path="/app/addlisting" component={AddEditEbayListing} />
          </Switch>
          <Box
            mt={5}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <div>
              <Link
                href={"https://www.facebook.com/oneclick"}
                target={"_blank"}
              >
                <IconButton aria-label="facebook">
                  <Icon path={FacebookIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://twitter.com"} target={"_blank"}>
                <IconButton aria-label="twitter">
                  <Icon path={TwitterIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://github.com"} target={"_blank"}>
                <IconButton aria-label="github" style={{ marginRight: -12 }}>
                  <Icon path={GithubIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
            </div>
          </Box>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
