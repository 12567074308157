import { configureStore } from "@reduxjs/toolkit";
import selectshoptoken from "../slices/shoptoken";
import selectCategories from "../slices/selectCategoies";

const store = configureStore({
  reducer: {
    selectshoptoken: selectshoptoken,
    selectCategories: selectCategories,
  },
});

export default store;
