import React, { useState } from "react";
import { db } from "../../firebase";
import { query, collection, where, getDocs } from "firebase/firestore";
import { useDispatch } from "react-redux";
export default function SelectedShop() {
  const [shoplist, setShoplist] = useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    try {
      const q = query(
        collection(db, "shops"),
        where("uid", "==", localStorage.getItem("uid")),
      );
      getDocs(q).then((res) => {
        const data = res.docs.map((item) => {
          var data = {
            id: item.id,
            clientSecret: item.data().clientSecret,
            clinetId: item.data().clinetId,
            plateform: item.data().plateform,
            shopName: item.data().shopName,
            status: item.data().status,
            uid: item.data().uid,
            access_token: item.data().access_token,
            refresh_token: item.data().refresh_token,
            productionOAuth: item.data().productionOAuth,
          };
          return data;
        });
        const { access_token, refresh_token, id } = data[0];
        dispatch({
          type: "SelectedShop",
          payload: {
            access_token,
            refresh_token,
            id,
          },
        });
        setShoplist(data);
      });
    } catch (err) {}
  }, [dispatch]);

  return (
    <>
      <span className="fw-bold fs-6 mx-1">Select Shop</span>
      <select
        onChange={(event) => {
          const { id, access_token, refresh_token } = shoplist[
            event.target.value
          ];

          dispatch({
            type: "SelectedShop",
            payload: {
              access_token,
              refresh_token,
              id,
            },
          });
        }}
        placeholder="Select Shop"
        className="form-select form-select-sm w-25"
        aria-label=".form-select-sm"
      >
        {shoplist.map((key, index) => {
          return (
            <option value={index} key={index.toString()}>
              {key.shopName}
            </option>
          );
        })}
      </select>
    </>
  );
}
