import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    doc,
    setDoc
} from "firebase/firestore";

import { toast } from 'react-toastify';


const firebaseConfig = {
    apiKey: "AIzaSyAsoBAkJwDkccg6gAU6T54RK23h9r9hwGM",
    authDomain: "oneclick-8dae3.firebaseapp.com",
    projectId: "oneclick-8dae3",
    storageBucket: "oneclick-8dae3.appspot.com",
    messagingSenderId: "1059967804384",
    appId: "1:1059967804384:web:22e898e2a46bdf7fa94185",
    measurementId: "G-ZLZKN68MZ7"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
            });
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const logInWithEmailAndPassword = async (email, password) => {
    try {
        const res = await signInWithEmailAndPassword(auth, email, password);
         getaccessandrefreshtoekn(res.user.uid);
        return res.user;
    } catch (err) {
        alert(err.message);
    }

};

const getaccessandrefreshtoekn = async (id) => {
    try {
        const q = query(collection(db, "shops"), where("uid", "==",id))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach( async(document) => {
          
            if(document.data().status==='Active' && document.data().plateform==='EBay'){
                window.localStorage.setItem('access_token', document.data().access_token)
                window.localStorage.setItem('refresh_token', document.data().refresh_token)
            }
         
        
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }

};

const registerWithEmailAndPassword = async (name, email, password, phoneNumber, address) => {

    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;


        const docRef = doc(db, "users", user.uid);
        await setDoc(docRef, {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
            phoneNumber,
            status: "pending",
            role: "user",
            address,
        }).then(function () {
            toast.success('Account created successfully!');
        }).catch((err) => {
            toast.error('Something Went Wroung!');
        });

        return user;
    } catch (err) {
        console.log(err.message);
    }
};
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        alert(err.message);
    }
};
const logout = () => {
    signOut(auth);
};
export {
    auth,
    db,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
};

