export const categories = {};
const selectCategories = (state = categories, action) => {
  switch (action.type) {
    case "SelectCetogories":
      console.log(action.payload);
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};
export default selectCategories;
