import React from "react";
import { Button } from "@material-ui/core";
import aboutUs1 from "../../images/aboutUs1.png";
import aboutUs2 from "../../images/aboutUs2.png";
import aboutUs3 from "../../images/aboutUs3.png";
import ownerCeo from "../../images/owner.jpeg";
import illus from "../../images/illus.png";
import avatar1 from "../../images/avatar1.png";
import avatar4 from "../../images/avatar4.png";
export default function AboutUs() {
  return (
    <>
      <Button
        style={{ backgroundColor: "pink" }}
        size="small"
        onClick={() => {
          window.location = "#/login";
        }}
        className="float-end my-5 mx-5"
      >
        Login
      </Button>
      <br />
      <br />
      <br /> <br /> <br />
      <br />
      <br />
      <div className="row">
        <div className="col-2"></div>
        <div className="col-8">
          <p className="fs-6">
            OneClic Limited is Bradford based company. That provide ecommerce
            solution to online sellers, Software development, digital marketing
            service and much more.
          </p>
        </div>
        <div className="col-2"></div>
      </div>
      <div className="row">
        <div className="col-2"></div>
        <div className="col-8">
          <p className="fs-6">
            Worldwide e-commerce business has been increasing continuously since
            last decade. According to Shopify, e-commerce gained $2.3 trillion
            in sales and is being estimated to $4.5 trillion in 2021. United
            Kingdom was ranked third largest e-commerce market in 2015. UK’s
            current e-commerce sales is being estimated as $84,885m. E-commerce
            sales in the UK will hit 21.6% compared to all retail sales in 2020
          </p>
        </div>
        <div className="col-2"></div>
      </div>
      <div className="row">
        <div className="col-2"></div>
        <div className="col-8">
          <p className="fs-6">
            There are 281,140 active sellers on Amazon.co.uk and eBay claim
            there are around 190,000 businesses registered on eBay UK. In the
            year 2017-18 Royal Mail delivered around 14.4 billion items and,
            whereas Parcelforce delivered around 1.2 billion parcels. This is a
            gigantic task to deal with the whole process of online sales without
            a digital platform. Sellers are using different types of platforms
            for following five key areas.
          </p>
        </div>
        <div className="col-2"></div>
      </div>
      <div className="row">
        <div className="col-2"></div>
        <div className="col-8">
          <ol type="number">
            <li className="fs-6">Ordering</li>
            <li className="fs-6">Advertisement</li>
            <li className="fs-6">Dispatching or posting</li>
            <li className="fs-6">Tracking</li>
            <li className="fs-6"> Customer support</li>
          </ol>
        </div>
        <div className="col-2"></div>
      </div>
      {/* ============== */}
      <div className="row h-100 align-items-center">
        <div className="col-lg-2"></div>
        <div className="col-lg-4">
          <p className="fs-6">
            Every task has its own sub-task and complexities. Dealing with all
            above processes individually and paying multiple subscription fee is
            not only a financial disadvantage but require more time and human
            resources.
          </p>
        </div>
        <div className="col-lg-4 d-none d-lg-block">
          <img src={illus} alt="" className="img-fluid" />
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <h1 className="text-center">Our team</h1>
        <br />
        <br />
        <div className="row text-center">
          <div className="col-xl-2"></div>
          <div className="col-xl-3 col-sm-6 mb-5">
            <div className="bg-white rounded shadow-sm py-4 px-4">
              <img
                src={avatar1}
                alt=""
                width="150"
                className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
              />
              <h5 className="mb-0">Manuella Nevoresky</h5>
              <span className="small text-uppercase text-muted">
                CEO - Founder
              </span>
            </div>
          </div>

          <div className="col-xl-3 col-sm-6 mb-5">
            <div className="bg-white rounded shadow-sm py-4 px-4">
              <img
                src={ownerCeo}
                alt=""
                width="150"
                className="img-fluid rounded-circle mb-2 img-thumbnail shadow-sm"
              />
              <h5 className="mb-0">IFTIKHAR ISMAIL</h5>
              <span className="small text-uppercase text-muted">
                Managing Director
              </span>
            </div>
          </div>

          <div className="col-xl-3 col-sm-6 mb-5">
            <div className="bg-white rounded shadow-sm py-4 px-4">
              <img
                src={avatar4}
                alt=""
                width="150"
                className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
              />
              <h5 className="mb-0">John Tarly</h5>
              <span className="small text-uppercase text-muted">
                CEO - Founder
              </span>
            </div>
          </div>

          <div className="col-xl-2"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-2"></div>
        <div className="col-8">
          <h4> 1. WHAT WE BELIEVE IN</h4>
          <h5 className="mx-5">1.1.Mission</h5>
          <p className="mx-5 fs-6">Working Smart is new way of working hard.</p>
          <h5 className="mx-5">1.2.Goal</h5>
          <p className="mx-5 fs-6">
            To become a most successful and innovative organisation in
            e-commerce.
          </p>
          <h5 className="mx-5">1.3.Business Philosophy</h5>
          <p className="mx-5 fs-6">
            Building online sellers capacity making them financially more
            competitive.
          </p>
          <h4> 2. Products and Services</h4>
          <p className="mx-4 fs-6">
            OneClic Limited is providing services in five key areas of online
            sale. Our top priority is to provide our clients a complete solution
            for every process involved. We provide them a flexibility option.
            They can customise the product according to their own need and
            unique demands.
          </p>
          <center>
            <img src={aboutUs1} alt="aboutUs1" />
          </center>

          <ol>
            <li className="fs-6">
              <b>Advertisement</b>
              <ul>
                <li className="fs-6">New trends</li>
                <li className="fs-6">Social media promotion</li>
                <li className="fs-6">Google search optimisation</li>
              </ul>
            </li>
            <li className="fs-6">
              <b> Stock and Sale management</b>
              <ul>
                <li className="fs-6">Managing stock at multiple platform</li>
                <li className="fs-6">
                  OneClick stock entry to all linked platforms{" "}
                </li>
                <li className="fs-6">
                  Prioritising stock to platform with bigger profit margin
                </li>
              </ul>
            </li>
            <li className="fs-6">
              <b> Dispatch management</b>
              <ul>
                <li className="fs-6">Making picking list</li>
                <li className="fs-6">Making packing list </li>
                <li className="fs-6">Producing postage lab </li>
                <li className="fs-6">
                  Choosing cheaper and quicker postage option
                </li>
                <li className="fs-6">Producing postage labels </li>
              </ul>
            </li>
            <li className="fs-6">
              <b> Tracking</b>
              <ul>
                <li className="fs-6">Item being picked</li>
                <li className="fs-6">Item being packed </li>
                <li className="fs-6">Item dispatched </li>
                <li className="fs-6">Estimated delivery date </li>
              </ul>
            </li>
            <li className="fs-6">
              <b>Orders and Inventory Management</b>
              <ul>
                <li className="fs-6">
                  Low stock warning depending on sale trend
                </li>
                <li className="fs-6">Preparing orders for suppliers </li>
                <li className="fs-6">Sending orders to sellers </li>
                <li className="fs-6">
                  Forecasting restocking dates for customers and seller{" "}
                </li>
              </ul>
            </li>
          </ol>

          <center>
            <img src={aboutUs2} width="100%" alt="aboutUs2" />
          </center>
          <p className="fs-6">
            One click is a company that provides services to online retailers
            from sourcing their products to happy customer feedback. It is a one
            stop shop experience. We provide a software based automated solution
            to companies and individuals selling their products worldwide by
            e-commerce. Our platform comprehensively deals with following six
            important aspects of
          </p>
          <center>
            <img src={aboutUs3} alt="aboutUs3" />
          </center>

          <h4>Online Sale. </h4>
          <ol>
            <li className="fs-6">Ordering</li>
            <li className="fs-6">Advertisement</li>
            <li className="fs-6">Selling</li>
            <li className="fs-6">Dispatching or posting </li>
            <li className="fs-6">Tracking</li>
            <li className="fs-6">Customer support</li>
          </ol>

          <p className="fs-6">
            It’s not only a wide range subscription-based service for all types
            of online sellers but also provides software based bespoke online
            solutions as well. One click is an ambitious business thinking
            beyond geographical and linguistic boundaries. After a successful
            completion of first year, we are going to launch our multilingual
            updated software to our worldwide online selling market. We are
            moving toward a Research and Development based organisation.{" "}
          </p>
          <p className="fs-6">
            If you are an online seller on multiple platforms like eBay, Amazon
            & WooCommerce, want to grow your business please signup now and one
            of our team members will contact you as soon as possible for your
            guidance.
          </p>

          <div className="row">
            <div className="col-4">
              <p className="fs-6 fw-bold">Email: Iftikhar.ismail@icloud.com</p>
              <p className="fs-6 fw-bold">Phone: 07728873283</p>
            </div>
            <div className="col-5"></div>
            <div className="col-3">
              <p className="fs-6 fw-bold">University of Bradford</p>
              <p className="fs-6 fw-bold">Bright Building</p>
              <p className="fs-6 fw-bold">BD7 1DB</p>
              <p className="fs-6 fw-bold">Bradford</p>
            </div>
          </div>
        </div>
        <div className="col-2"></div>
      </div>
      <footer className="bg-light pb-5">
        <div className="container text-center">
          <p className="font-italic text-muted mb-0">
            &copy; Copyrights OneClick All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}
