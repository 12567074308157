import React, { useEffect, useState } from 'react'
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Tooltip,
    Button,
    Typography,
    CircularProgress,
    Modal,
    Box,
    Card,
    CardContent,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { API_URL } from '../../config';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
function UnShipped() {

    const [messages, setMessages] = useState([]);
    const [openmodel, setOpenModel] = React.useState(false);
    const handleOpenModel = () => setOpenModel(true);
    const handleCloseModel = () => setOpenModel(false);

    const [seletecOrder, setSeletecOrder] = useState({})
    const [isdataloading, setIsdataloading] = useState(false)
    const { shop } = useSelector(state => state.selectshoptoken);
    const [isloading, setIsloading] = useState(false);
    const [openeditmodel, setOpeneditModel] = React.useState(false);
    const handleOpenEditModel = () => setOpeneditModel(true);
    const handleCloseEditModel = () => setOpeneditModel(false);

    const [sku, setSku] = useState('');
    const [quantity, setQuantity] = useState('');
    const [itemId, setItemId] = useState('');

    useEffect(() => {
        setIsdataloading(true)
        const { access_token } = shop;
        axios.post(`${API_URL}/getorders`, {
            token: access_token,
            "limit": "100",
            "offset": "0",
            "ordertype": "unshipped"
        })
            .then((response) => {
                const { data } = response;
                const { orders } = data.data;

                const shippeddata = orders.map(item => {
                    const {
                        orderId,
                        creationDate,
                        orderPaymentStatus,
                        orderFulfillmentStatus,
                        sellerId,
                        buyer,
                        pricingSummary,
                        salesRecordReference,
                        fulfillmentStartInstructions,
                        totalFeeBasisAmount,
                        lineItems
                    } = item;
                    const { username } = buyer;
                    const { priceSubtotal, deliveryCost, total } = pricingSummary;
                    const { maxEstimatedDeliveryDate, shippingStep, minEstimatedDeliveryDate } = fulfillmentStartInstructions[0];
                    const { shipTo, shippingCarrierCode } = shippingStep;
                    const { primaryPhone, contactAddress } = shipTo;
                    const { phoneNumber } = primaryPhone;
                    const { addressLine1, addressLine2, city, stateOrProvince, postalCode, countryCode } = contactAddress;
                    const { value } = totalFeeBasisAmount;

                    var data = {
                        'orderId': orderId,
                        'creationDate': creationDate,
                        'orderPaymentStatus': orderPaymentStatus,
                        'orderFulfillmentStatus': orderFulfillmentStatus,
                        'sellerId': sellerId,
                        'buyer_name': username,
                        'priceSubtotal': priceSubtotal,
                        'deliveryCost': deliveryCost,
                        'total': total,
                        'salesRecordReference': salesRecordReference,
                        'deliveryDate': maxEstimatedDeliveryDate,
                        'totalFeeBasisAmount': value,
                        'phoneNumber': phoneNumber,
                        'shippingCarrierCode': shippingCarrierCode,
                        'minEstimatedDeliveryDate': minEstimatedDeliveryDate,
                        'city': city,
                        'postalCode': postalCode,
                        'CityStateProvince': `${stateOrProvince} ${countryCode}`,
                        'PostcodeCountryRegion': `${postalCode} ${addressLine1} ${addressLine2} UK`,
                        'itemlist': lineItems
                    }
                    return data;
                })
                setMessages(shippeddata)
                setIsdataloading(false)
            })
            .catch(function (error) {
                setIsdataloading(false)
            });

    }, [shop])


    const dispatchedorder = (link) => {
        window.location = link;
    }

    const columns = [
        {
            name: "orderId",
            options: {
                filter: false
            }
        },
        {
            name: "sellerId",
            options: {
                filter: false
            }
        },


        {
            name: "creationDate",
            options: {
                filter: false
            }
        },
        {
            name: "buyer_name",
            options: {
                filter: false
            }
        },

        {
            name: "orderPaymentStatus",
            options: {
                filter: false
            }
        },
        {
            name: "orderFulfillmentStatus",
            options: {
                filter: false
            }
        },
        {
            name: "View Details",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const { rowData } = tableMeta;

                    return (
                        <>
                            <Tooltip title="View Details">
                                <Button className='mx-2'>
                                    <OpenInNewIcon style={{ color: 'blue' }} onClick={() => {

                                        messages.forEach((item) => {
                                            if (item.orderId === rowData[0]) {
                                                setSeletecOrder(item)
                                            }
                                        })

                                        handleOpenModel()
                                    }} />
                                </Button>
                            </Tooltip>
                        </>
                    );
                }
            }
        },
        {
            name: "Dispatch Order",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const { rowData } = tableMeta;
                    var link2 = `#/app/dispatchorder/${rowData[0]}`
                    let colorstatus = rowData[5] === 'NOT_STARTED' ? 'red' : rowData[5] === 'IN_PROGRESS' ? 'green' : 'grey'


                    return (
                        <>
                            <Tooltip title={rowData[5]}>
                                <Button className='mx-2'>
                                    <LocalShippingIcon style={{ color: colorstatus }} onClick={(event) => {
                                        event.preventDefault();
                                        rowData[5] === 'NOT_STARTED' ?
                                            dispatchedorder(link2)
                                            : rowData[5] === 'IN_PROGRESS' ?
                                                alert('Already in Progress!!')
                                                : console.log('none')
                                    }} />
                                </Button>
                            </Tooltip>
                        </>
                    );
                }
            }
        },

        {
            name: "Edit",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const { rowData } = tableMeta;
                  
                    return (
                        <>
                            <Tooltip title="Edit">
                                <Button className='mx-2'>
                                    <EditIcon style={{ color: 'blue' }} onClick={() => {
                                        messages.forEach((item) => {
                                            if (item.orderId === rowData[0]) {
                                                const {sku,quantity,legacyItemId} =item.itemlist[0]
                                                setSku(sku)
                                                setQuantity(quantity)
                                                setItemId(legacyItemId)
                                            }
                                        })
                                  
                                        handleOpenEditModel(true)
                                    }} />
                                </Button>
                            </Tooltip>
                        </>
                    );
                }
            }
        },
    ];

    const useStyles = makeStyles(theme => ({
        paper: {
            position: "absolute",
            width: "70%",
            height: "90%",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            outline: "none",
            overflowY: 'scroll'
        }
    }));
    const classes = useStyles();
    const modelstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70%",
        boxShadow: 24,
        p: 4,
    };
    const editmodelstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",
        height: "50%",
        boxShadow: 24,
        p: 4,
    };

    const notify = (mgs, type) => toast(mgs, {

        position: "top-right",
        theme: 'light',
        type: type,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const updatequantity = () => {
        try {
            const { access_token } = shop;
            setIsloading(true)
            axios.post(`${API_URL}/reviseinventorystatus`, {
                ItemID: itemId,
                SKU: sku,
                Quantity: quantity,
                token: access_token
            }).then((res) => {
                setIsloading(false)
                notify('Record Updated', 'success')
            }).catch((err) => {
                notify('Something went wroung!!', 'error')
                setIsloading(false)
            })
        } catch (error) {

        }


    }

    return (
        <>
            <div className='row'>
                <div className='fw-bold fs-5'>UnShipped Product</div>
            </div>
            <br /><br />

            <Modal
                open={openmodel}
                onClose={handleCloseModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={modelstyle} className={classes.paper}>
                    <div>
                        <h3>Order Numbers {seletecOrder.orderId}</h3>
                    </div>


                    <div className='row'>
                        <Box className={classes.root}>
                            <Card>
                                <CardContent>

                                    <div>
                                        <h6>Estimated delivery date shown to buyer: {seletecOrder.deliveryDate}</h6>
                                    </div>
                                </CardContent>

                            </Card>
                        </Box>
                    </div>
                    <br />
                    <br />
                    <div className='row'>
                        <div className='col-5'>
                            <Box className={classes.root}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h3">Purchase details</Typography>
                                        <div className='row'>
                                            <div className='col-4'> <h6>Buyer</h6></div>
                                            <div className='col-8'><p>{seletecOrder.buyer_name}</p></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-4'><h6>Date sold</h6> </div>
                                            <div className='col-8'> <p>{seletecOrder.creationDate}</p> </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-4'> <h6>Date buyer paid</h6> </div>
                                            <div className='col-8'> <p>{seletecOrder.creationDate}</p> </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-4'> <h6>Sales  number</h6> </div>
                                            <div className='col-8'> <p>{seletecOrder.salesRecordReference}</p> </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-4'>  <h6>Order total</h6>  </div>
                                            <div className='col-8'> <p>£ {seletecOrder.totalFeeBasisAmount}</p></div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-4'> <h6>Phone</h6> </div>
                                            <div className='col-8'><p>{seletecOrder.phoneNumber}</p> </div>
                                        </div>

                                    </CardContent>

                                </Card>
                            </Box>
                        </div>
                        <div className='col-7'>
                            <Box className={classes.root}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h3">Delivery details</Typography>

                                        <div className='row'>
                                            <div className='col-3'> <h6>Delivery service</h6></div>
                                            <div className='col-9'><p>{seletecOrder.shippingCarrierCode}</p></div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-3'> <h6>Date dispatched</h6></div>
                                            <div className='col-9'><p>{seletecOrder.minEstimatedDeliveryDate}</p></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3'> <h6>Post to Street</h6></div>
                                            <div className='col-9'><p>{seletecOrder.postalCode}</p></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3'> <h6>City</h6></div>
                                            <div className='col-9'><p>{seletecOrder.city}</p></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3'> <h6>City/State/Province</h6></div>
                                            <div className='col-9'><p>{seletecOrder.CityStateProvince}</p></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3'> <h6>Country/Region</h6></div>
                                            <div className='col-9'><p>{seletecOrder.PostcodeCountryRegion}</p></div>
                                        </div>
                                    </CardContent>

                                </Card>
                            </Box>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className='row'>
                        <Typography variant="h3" className='text-center'>Items</Typography>
                        <Box className={classes.root}>
                            <Card>
                                <CardContent >


                                    {
                                        seletecOrder.itemlist !== undefined ?
                                            <>

                                                {
                                                    seletecOrder.itemlist.map((iteminlist, index) => {
                                                       
                                                        const { title, quantity, lineItemCost } = iteminlist;
                                                        const { value } = lineItemCost;
                                                        return <Box className={classes.root} key={index}>
                                                            <Card >
                                                                <Typography variant="h4" className='mx-3 '>Item # {seletecOrder.itemlist.length}</Typography>
                                                                <CardContent>

                                                                    <div className='row'>
                                                                        <div className='col-3'> <h6>Item Name</h6></div>
                                                                        <div className='col-9'><p>{title}</p></div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-3'> <h6>Quantity</h6></div>
                                                                        <div className='col-9'><p>{quantity}</p></div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-3'> <h6>Sold for</h6></div>
                                                                        <div className='col-9'><p>£ {value}</p></div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-3'> <h6>Total</h6></div>
                                                                        <div className='col-9'><p>£ {value}</p></div>
                                                                    </div>
                                                                </CardContent>

                                                            </Card>
                                                            <br />
                                                        </Box>;
                                                    })
                                                }
                                            </>

                                            : null
                                    }
                                </CardContent>

                            </Card>
                        </Box>
                    </div>
                </Box>
            </Modal>


            <Modal
                open={openeditmodel}
                onClose={handleCloseEditModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={editmodelstyle} className={classes.paper}>
                    <div>
                        <h3>Edit Sku</h3>
                    </div>


                    <div className='row'>
                        <Box className={classes.root}>
                            <Card>
                                <CardContent>

                                    <div>
                                        <div className="form-floating mb-3">
                                            <input type="text" value={sku} onChange={(event) => {
                                                setSku(event.target.value)

                                            }} class="form-control" id="floatingInput" placeholder="SkU" />
                                            <label >SkU</label>
                                        </div>
                                        <br />
                                        <div className="form-floating">
                                            <input type="text" value={quantity} onChange={(event) => {

                                                setQuantity(event.target.value)

                                            }} class="form-control" id="floatingInput" placeholder="Quantity" />
                                            <label>Quantity</label>
                                        </div>
                                        <br />
                                        <div className="form-floating">
                                            <input type="text" value={itemId} onChange={(event) => {
                                                setItemId(event.target.value)
                                            }} class="form-control" id="floatingInput" placeholder="Item Id" />
                                            <label>Item Id</label>
                                        </div>

                                        {isloading ?
                                            <div className="mt-5 text-center">
                                                <CircularProgress size={26} />
                                            </div> :
                                            <button type="primary" onClick={() => {
                                                updatequantity()
                                            }} className="btn btn-success float-end my-3">Update</button>}

                                    </div>

                                </CardContent>

                            </Card>
                        </Box>
                    </div>
                    <br />
                    <br />


                </Box>
            </Modal>

            <Grid item xs={12}>

                {
                    isdataloading ?
                        <div className="my-5 text-center">
                            <CircularProgress size={25} /><span className='fs-3 fw-bold mx-3'> loading...</span>
                        </div>
                        :
                        <MUIDataTable
                            title="Shops List"
                            data={messages}
                            columns={columns}
                            options={{
                                sort: true,
                                selectableRows: 'none',

                            }}
                        />
                }


            </Grid>
        </>

    )





}

export default UnShipped;