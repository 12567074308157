import React, { useState } from "react";

import {
  Grid,
  TextField,
  CircularProgress,
  Button,

} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import NotificationsPage from '../notifications/Notifications';
import { auth, db } from '../../firebase';
import { toast } from 'react-toastify';
import {
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";


const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))


export default function UpdateShop(props) {
  const classes = useStyles();
  const [companyName, setCompanyName] = useState('');
  const [category, setCategory] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [isloading, setIsloading] = useState(false);

  React.useEffect(() => {

    const getsinglerecord = async () => {

      const docRef = doc(db, "suppliers", props.match.params.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
  
        setCompanyName(docSnap.data().companyName);
        setCategory(docSnap.data().category);
        setContactPerson(docSnap.data().contactPerson);
        setNumber(docSnap.data().number);
        setEmail(docSnap.data().email);
        setAddressLine1(docSnap.data().addressLine1);
        setAddressLine2(docSnap.data().addressLine2);
        setCountry(docSnap.data().country);
        setCity(docSnap.data().city);
        setPostcode(docSnap.data().postcode);
      } 
    }

    getsinglerecord();

  }, [props.match.params.id, isloading])


  const updateshoptodb = async () => {
    setIsloading(true);
    try {
      const docRef = doc(db, "suppliers", props.match.params.id);
      await updateDoc(docRef, {
        uid: auth.currentUser.uid,
        companyName: companyName,
        category: category,
        contactPerson: contactPerson,
        number: number,
        email: email,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        country: country,
        city: city,
        postcode: postcode,
      }).then(() => {
        toast.success('Supplier Updated successfully');
        setIsloading(false);
      });
    } catch (err) {
      setIsloading(false);
    }
  }



  return (
    <>
      <NotificationsPage />
      <PageTitle title="Update Supplier" />
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Grid container spacing={4}>

              <Grid item xs={6} >
                <TextField required id="outlined-basic" label="Company Name"
                  placeholder="Company Name"
                  onChange={(event) => {
                    setCompanyName(event.target.value)
                  }}
                  value={companyName}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6} >
                <TextField required id="outlined-basic" label="Category"
                  placeholder="Category"
                  onChange={(event) => {
                    setCategory(event.target.value)
                  }}
                  value={category}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="outlined-basic" label="Contact Person"
                  placeholder="Contact Person"
                  onChange={(event) => {
                    setContactPerson(event.target.value)
                  }}
                  value={contactPerson}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="outlined-basic" label="Phone Number"
                  placeholder="Phone Number"
                  onChange={(event) => {
                    setNumber(event.target.value)
                  }}
                  value={number}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="outlined-basic" label="Email Address"
                  placeholder="Email Address"
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  value={email}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="outlined-basic" label="Address line 1"
                  placeholder="Address"
                  onChange={(event) => {
                    setAddressLine1(event.target.value)
                  }}
                  value={addressLine1}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="outlined-basic" label="Address line 2"
                  placeholder="Address"
                  onChange={(event) => {
                    setAddressLine2(event.target.value)
                  }}
                  value={addressLine2}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="outlined-basic" label="City"
                  placeholder="City"
                  onChange={(event) => {
                    setCity(event.target.value)
                  }}
                  value={city}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="outlined-basic" label="Post Code"
                  placeholder="Post Code"
                  onChange={(event) => {
                    setPostcode(event.target.value)
                  }}
                  value={postcode}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="outlined-basic" label="Country"
                  placeholder="Country"
                  onChange={(event) => {
                    setCountry(event.target.value)
                  }}
                  value={country}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <div className={classes.creatingButtonContainer}>
                  {isloading ? (
                    <div className="mt-5 text-center">
                      <CircularProgress size={26} />
                    </div>
                  ) : (
                    <Button
                      onClick={() => updateshoptodb()}
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.createAccountButton}
                    >
                      Update Supplier
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
              </Grid>
            </Grid>
          </React.Fragment>
        </div>

      </div>
    </>
  );



}

