import React, { useState } from "react";

import {
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Button,

} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import NotificationsPage from '../notifications/Notifications';
import { auth, db } from '../../firebase';
import { toast } from 'react-toastify';
import {
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";


const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

const currencies = [
  {
    value: 'Amazon',
    label: 'Amazon',
  },
  {
    value: 'EBay',
    label: 'EBay',
  },
  {
    value: 'WooCommerce',
    label: 'WooCommerce',
  },

];

export default function UpdateShop(props) {
  const classes = useStyles();
  const [shopName, setshopName] = useState('');
  const [clinetId, setClinetId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [plateform, setplateform] = React.useState('Amazon');
  const [isloading, setIsloading] = useState(false);

  React.useEffect(() => {

    const getsinglerecord = async () => {


      const docRef = doc(db, "shops", props.match.params.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setshopName(docSnap.data().shopName);
        setClinetId(docSnap.data().clinetId);
        setClientSecret(docSnap.data().clientSecret);
        setplateform(docSnap.data().plateform);
      } 
    }

    getsinglerecord();

  }, [props.match.params.id,isloading])


  const updateshoptodb = async () => {
    setIsloading(true);
    try {
      const docRef = doc(db, "shops", props.match.params.id);
      await updateDoc(docRef, {
        uid: auth.currentUser.uid,
        shopName: shopName,
        clinetId: clinetId,
        clientSecret: clientSecret,
        plateform: plateform,
      }).then( ()=> {      
        toast.success('Shop Updated successfully');
        setIsloading(false);
      });
    } catch (err) {
      setIsloading(false);
    }

  }



  return (
    <>
      <NotificationsPage />
      <PageTitle title="Update Shops" />
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>


            <Grid container spacing={4}>
              <Grid item xs={6} >
                <TextField
                  id="plateforn name"
                  select
                  label="Select Platform"
                  value={plateform}
                  onChange={(event) => {
                    setplateform(event.target.value)
                  }}
                  variant="outlined" fullWidth
                  helperText="Select Platform"

                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} ></Grid>
              <Grid item xs={6}>
                <TextField id="outlined-basic" label="Shop Name"
                  placeholder="Shop Name"
                  onChange={(event) => {
                    setshopName(event.target.value)
                  }}
                  value={shopName}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>

              </Grid>
              <Grid item xs={6}>
                <TextField id="outlined-basic" label="Client Id"
                  placeholder="Client Id"
                  onChange={(event) => {
                    setClinetId(event.target.value)
                  }}
                  value={clinetId}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>

              </Grid>
              <Grid item xs={6}>
                <TextField id="outlined-basic" label="Client Secret"
                  placeholder="Client Secret"
                  onChange={(event) => {
                    setClientSecret(event.target.value)
                  }}
                  value={clientSecret}
                  variant="outlined" fullWidth />
              </Grid>
              <Grid item xs={6}>

              </Grid>

              <Grid item xs={6}>
                <div className={classes.creatingButtonContainer}>
                  {isloading ? (
                    <div className="mt-5 text-center">
                      <CircularProgress size={26} />
                    </div>
                  ) : (
                    <Button
                      onClick={() => updateshoptodb()}
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.createAccountButton}
                    >
                      Update Shop
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>

              </Grid>
            </Grid>







          </React.Fragment>
        </div>

      </div>
    </>
  );



}

