import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Button
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { db, auth } from '../../firebase';
import { toast } from 'react-toastify';
// components
import {
  Link,
} from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PageTitle from "../../components/PageTitle";

import {
  deleteDoc,
  getDocs,
  doc,
  query,
  collection,
  where
} from "firebase/firestore";


export default function Supplier() {

  const [datatableData, setDatatableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState();
  const theme = useTheme();
  const [deleteid, setDeleteid] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handledelete = async (e) => {

    e.preventDefault();
    setOpen(false);
    const docRef = doc(db, "suppliers", deleteid);
    deleteDoc(docRef).then((res) => {
      notify('Supplier Deleted successfully!')
      setOpen(false);
      setData([]);
    }).catch((err) => {
      setOpen(false);
    });

  }

  const notify = (mgs) => toast(mgs, {

    position: "top-right",
    theme: 'light',
    type: "success",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  React.useEffect(() => {
    try {


      const q = query(collection(db, "suppliers"), where("uid", "==", auth?.currentUser?.uid));
      getDocs(q).then((res) => {
        const data = res.docs.map(item => {

          var data = {
            "id": item.id,
            "Name": item.data().companyName,
            "Category": item.data().category,
            "Number": item.data().number,
            "Email": item.data().email,
            "Postcode": item.data().postcode,
          }
          return data;
        })
        setDatatableData(data)
      });


    } catch (err) {}

  }, [data])

  const columns = [
    {
      name: "id",
      options: {
        filter: true,
      }
    },
    {
      name: "Name",
      options: {
        filter: false
      }
    },

    {
      name: "Category",
      options: {
        filter: true
      }
    },

    {
      name: "Number",
      options: {
        filter: false
      }
    },
    {
      name: "Email",
      options: {
        filter: true,
      }
    },
    {
      name: "Postcode",
      options: {
        filter: true,
      }
    },
    {
      name: "Action",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;
          var link = `/app/updatesupp/${rowData[0]}`
          return (
            <>
              <Link to={link} >
                <EditIcon className='mx-2' />
              </Link>
              <Button className='mx-2'>
                <DeleteIcon onClick={() => {
                  handleClickOpen();
                  setDeleteid(rowData[0]);
                }
                }
                />
              </Button>
            </>
          );
        }
      }
    }
  ];

  return (
    <>
      <PageTitle title="Suppliers" />

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Supplier?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete supplier?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handledelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <MUIDataTable
          title="Suppliers List"
          data={datatableData}
          columns={columns}
          options={{
            selectableRows: 'none',
            sort: true,
          }}
        />
      </Grid>
    </>
  );

}

