import React from "react";
import { logInWithEmailAndPassword, registerWithEmailAndPassword } from "../firebase";
import { toast } from 'react-toastify';
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE": {
      return { ...state, isAuthenticated: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}



// ###########################################################
const loginUser = async (dispatch, login, password, history, setIsLoading, setError) => {
  setError(false);
  setIsLoading(true);

  const res = await logInWithEmailAndPassword(login, password);
  console.log("!Login Response " + JSON.stringify(res));

  if (!!login && !!res) {
    setTimeout(() => {
      localStorage.setItem('id_token', 1)
      localStorage.setItem("userName", res.email);
      localStorage.setItem("role", "user");
      localStorage.setItem("uid", res.uid);


      setError(null)
      setIsLoading(false)
      dispatch({ type: 'LOGIN_SUCCESS' })
      toast.success('Login successfully!');
      history.push('/app/dashboard')
    }, 2000);
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}
const SignupUser = async (dispatch, nameValue, emailValue, password, phoneValue, addrValue, history, setIsLoading, setError) => {
  setError(false);
  setIsLoading(true);

  console.log(nameValue + emailValue + password + phoneValue + addrValue);
  const res = await registerWithEmailAndPassword(nameValue, emailValue, password, phoneValue, addrValue);
  console.log("!Sign Up Response " + JSON.stringify(res));


  if (!!emailValue && !!res) {
    setTimeout(() => {
      localStorage.setItem('id_token', 1)
      localStorage.setItem("userName", res.email);
      localStorage.setItem("role", "user");

      setError(null)
      setIsLoading(false)
      dispatch({ type: 'LOGIN_SUCCESS' })

      history.push('/app/dashboard')
    }, 2000);
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.clear()
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}


export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, SignupUser };