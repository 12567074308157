import React, { useState, useEffect } from "react";

import {
  CircularProgress,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  Box,
  Button,
  Modal,
} from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { auth, db } from "../../firebase";
import DeleteIcon from "@material-ui/icons/Delete";
import { collection, addDoc } from "firebase/firestore";
import ebaylogo from "../../images/ebaylogo.png";
import postagepolicy from "./postagespolicy.json";
import EbayCategories from "../ebayCategories/ebaycategories";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default function AddProduct() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.selectCategories);

  console.log("categories in listing", categories);
  const [isloading, setIsloading] = useState(false);
  const [PictureURL, setPictureURL] = useState([]);
  const [addimage, setAddimage] = useState("");
  const [title, setTitle] = useState("");
  const [Description, setDescription] = useState();
  const [CategoryID, setCategoryID] = useState("");
  const [CategoryName, setCategoryName] = useState("");
  const [ConditionID, setConditionID] = useState("");
  const [StartPrice, setStartPrice] = useState("");
  const [Country, setCountry] = useState("");
  const [Currency, setCurrency] = useState("");
  const [DispatchTimeMax, setDispatchTimeMax] = useState("");
  const [Location, setLocation] = useState("");
  const [SKU, setSKU] = useState("");
  const [Brand, setBrand] = useState("");
  const [PostalCode, setPostalCode] = useState("");
  const [Type, setType] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [ReturnsWithinOption, setReturnsWithinOption] = useState("");
  const [ReturnsWithin, setReturnsWithin] = useState("");
  const [ReturnsAcceptedOption, setReturnsAcceptedOption] = useState("");
  const [ShippingIncludedInTax, setShippingIncludedInTax] = useState(false);
  const [ApplyShippingDiscount, setApplyShippingDiscount] = useState(false);
  const [SalesTaxPercent, setSalesTaxPercent] = useState("");
  const [ShippingService, setShippingService] = useState("");
  const [ShippingServiceCost, setShippingServiceCost] = useState("");
  const [
    ShippingServiceAdditionalCost,
    setShippingServiceAdditionalCost,
  ] = useState("");
  const [ShippingServicePriority, setShippingServicePriority] = useState("");
  const [ExpeditedService, setExpeditedService] = useState(false);
  const [ShippingTimeMin, setShippingTimeMin] = useState("");
  const [ShippingTimeMax, setShippingTimeMax] = useState("");
  const [FreeShipping, setFreeShipping] = useState(false);
  const [Site, setSite] = useState("");

  const [openmodel, setOpenModel] = React.useState(false);
  const handleOpenModel = () => setOpenModel(true);
  const handleCloseModel = () => setOpenModel(false);

  useEffect(() => {
    if (categories) {
      setCategoryID(categories.CategoryID);
      setCategoryName(categories.CategoryName);
      handleCloseModel();
    }
  }, [categories]);

  useEffect(() => {}, [PictureURL]);

  const addproductshoptodb = async () => {
    try {
      console.log(Description);
      if (
        title !== "" &&
        Description !== "" &&
        CategoryID !== "" &&
        CategoryName !== "" &&
        ConditionID !== "" &&
        StartPrice !== "" &&
        Country !== "" &&
        Currency !== "" &&
        DispatchTimeMax !== "" &&
        Location !== "" &&
        SKU !== "" &&
        Type !== "" &&
        PostalCode !== "" &&
        Quantity !== "" &&
        Brand !== "" &&
        SalesTaxPercent !== "" &&
        ShippingService !== "" &&
        ShippingServiceCost !== "" &&
        ShippingTimeMin !== "" &&
        ShippingTimeMax !== "" &&
        Site !== "" &&
        ShippingServiceAdditionalCost !== ""
      ) {
        var datatosend = {
          uid: auth.currentUser.uid,
          status: "Not Published",
          Title: title,
          Description: Description,
          CategoryID: CategoryID,
          CategoryName: CategoryName,
          ConditionID: ConditionID,
          StartPrice: StartPrice,
          Country: Country,
          Currency: Currency,
          DispatchTimeMax: DispatchTimeMax,
          Location: Location,
          SKU: SKU,
          Brand: Brand,
          PostalCode: PostalCode,
          Type: Type,
          Quantity: Quantity,
          ReturnsWithinOption: ReturnsWithinOption,
          ReturnsWithin: ReturnsWithin,
          ReturnsAcceptedOption: ReturnsAcceptedOption,
          ShippingIncludedInTax: ShippingIncludedInTax,
          ApplyShippingDiscount: ApplyShippingDiscount,
          SalesTaxPercent: SalesTaxPercent,
          ShippingService: ShippingService,
          ShippingServiceCost: ShippingServiceCost,
          ShippingServiceAdditionalCost: ShippingServiceAdditionalCost,
          ShippingServicePriority: ShippingServicePriority,
          ExpeditedService: ExpeditedService,
          ShippingTimeMin: ShippingTimeMin,
          ShippingTimeMax: ShippingTimeMax,
          FreeShipping: FreeShipping,
          Site: Site,
          PictureURL: PictureURL,
        };

        setIsloading(true);
        await addDoc(collection(db, "products"), datatosend).then(function () {
          setIsloading(false);
          toast.success("Product Added successfully");
          resetform();
        });
      } else {
        toast.error("Mandatory Field cannot be empty!.");
      }
    } catch (err) {
      setIsloading(false);
    }
  };

  const resetform = () => {
    setPictureURL([]);
    setAddimage("");
    setTitle("");
    setDescription("");
    setCategoryID("");
    setCategoryName("");
    setConditionID("");
    setStartPrice("");
    setCountry("");
    setCurrency("");
    setDispatchTimeMax("");
    setLocation("");
    setSKU("");
    setBrand("");
    setPostalCode("");
    setType("");
    setQuantity("");
    setReturnsWithinOption("");
    setReturnsWithin("");
    setReturnsAcceptedOption("");
    setShippingIncludedInTax(false);
    setApplyShippingDiscount(false);
    setSalesTaxPercent("");
    setShippingService("UK_RoyalMailSpecialDeliveryNextDay");
    setShippingServiceCost("");
    setShippingServiceAdditionalCost("");
    setShippingServicePriority("");
    setExpeditedService(false);
    setShippingTimeMin("");
    setShippingTimeMax("");
    setFreeShipping(false);
    setSite("");
  };

  const modelstyle = {
    position: "absolute",
    color: "#000000",
    border: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Modal
            open={openmodel}
            onClose={handleCloseModel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={modelstyle} className={classes.paper}>
              <EbayCategories />
            </Box>
          </Modal>

          <React.Fragment>
            <form>
              <div>
                <div className="d-flex justify-content-center">
                  <img src={ebaylogo} width="160" height="70" alt="ebaylogo" />
                </div>
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <hr />
                  </div>
                  <div className="col-1"></div>
                </div>

                <h3 className="row">
                  <div className="col-1"></div>
                  <div className="col-4">Product Details</div>
                </h3>
                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Title</label>
                      <input
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-5">
                    <label className="form-label">CategoryID</label>
                    <div className="input-group ">
                      <input
                        type="text"
                        className="form-control"
                        value={CategoryID}
                        onChange={(event) => setCategoryID(event.target.value)}
                        aria-describedby="addon-wrapping"
                        disabled
                      />
                      <span
                        onClick={() => {
                          handleOpenModel();
                        }}
                        className="input-group-text"
                        id="addon-wrapping"
                      >
                        ...
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">CategoryName</label>
                      <div className="input-group ">
                        <input
                          type="text"
                          value={CategoryName}
                          onChange={(event) =>
                            setCategoryName(event.target.value)
                          }
                          className="form-control"
                          aria-describedby="addon-wrapping"
                          disabled
                        />
                        <span
                          onClick={() => {
                            handleOpenModel();
                          }}
                          className="input-group-text"
                          id="addon-wrapping"
                        >
                          ...
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">ConditionID</label>
                      <input
                        value={ConditionID}
                        onChange={(event) => setConditionID(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Start Price</label>
                      <input
                        value={StartPrice}
                        onChange={(event) => setStartPrice(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">SKU</label>
                      <input
                        value={SKU}
                        onChange={(event) => setSKU(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-11">
                  <div className="form-outline mt-4 col-11">
                    <label className="form-label">Product Description</label>
                    <div className="form-group col-md-12 editor">
                      <CKEditor
                        data={Description}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            "|",
                            "|",
                            "insertTable",
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                            "|",
                            "undo",
                            "redo",
                          ],
                        }}
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                          // console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10 my-3">
                  <div className="input-group ">
                    <input
                      type="text"
                      className="form-control"
                      value={addimage}
                      onChange={(event) => {
                        setAddimage(event.target.value);
                      }}
                      placeholder="Image Url"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                    />
                    <button
                      onClick={() => {
                        PictureURL.push(addimage);
                        setAddimage("");
                      }}
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2"
                    >
                      Add
                    </button>
                  </div>
                  <br />
                  {PictureURL.map((key, index) => {
                    return (
                      <div key={index.toString()}>
                        <ul>
                          <div className="row">
                            <div className="col-6">
                              <li>{key}</li>
                            </div>
                            <div className="col-6">
                              <Button
                                onClick={() => {
                                  setPictureURL([
                                    ...PictureURL.slice(0, index),
                                    ...PictureURL.slice(index + 1),
                                  ]);
                                }}
                                className="float-end"
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </Button>{" "}
                            </div>
                          </div>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <h3 className="row">
                  <div className="col-1"></div>
                  <div className="col-4">
                    <h3>Shipping Details</h3>
                  </div>
                </h3>

                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Country</label>
                      <input
                        value={Country}
                        onChange={(event) => setCountry(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Currency</label>
                      <input
                        value={Currency}
                        onChange={(event) => setCurrency(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Location</label>
                      <input
                        value={Location}
                        onChange={(event) => setLocation(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Brand</label>
                      <input
                        value={Brand}
                        onChange={(event) => setBrand(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Type</label>
                      <input
                        value={Type}
                        onChange={(event) => setType(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Quantity</label>
                      <input
                        value={Quantity}
                        onChange={(event) => setQuantity(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-4">
                    <div className="form-outline">
                      <label className="form-label">Postal Code</label>
                      <input
                        value={PostalCode}
                        onChange={(event) => setPostalCode(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h3 className="row">
                  <div className="col-1"></div>
                  <div className="col-4">
                    <h3>Other Details</h3>
                  </div>
                </h3>

                <div className="row my-4">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Shipping Included In Tax
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={ShippingIncludedInTax}
                        onChange={(event) =>
                          setShippingIncludedInTax(event.target.value)
                        }
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="col-5">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Apply Shipping Discount
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={ApplyShippingDiscount}
                        onChange={(event) =>
                          setApplyShippingDiscount(event.target.value)
                        }
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Expedited Service
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={ExpeditedService}
                        onChange={(event) =>
                          setExpeditedService(event.target.value)
                        }
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="col-5">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Free Shipping
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={FreeShipping}
                        onChange={(event) =>
                          setFreeShipping(event.target.value)
                        }
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="form-outline">
                      <label className="form-label">Shipping Service</label>

                      <select
                        onChange={(event) => {
                          console.log(event.target.value);
                        }}
                        placeholder="Select Shop"
                        className="form-select form-select-sm w-100"
                        aria-label=".form-select-sm"
                      >
                        {postagepolicy.map((key, index) => {
                          return (
                            <option value={key} key={index.toString()}>
                              {key}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">
                        Shipping Service Priority
                      </label>
                      <input
                        value={ShippingServicePriority}
                        onChange={(event) =>
                          setShippingServicePriority(event.target.value)
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">
                        Shipping Service Additional Cost
                      </label>
                      <input
                        value={ShippingServiceAdditionalCost}
                        onChange={(event) =>
                          setShippingServiceAdditionalCost(event.target.value)
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Site</label>
                      <input
                        value={Site}
                        onChange={(event) => setSite(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">
                        Returns Within Option
                      </label>
                      <input
                        value={ReturnsWithinOption}
                        onChange={(event) =>
                          setReturnsWithinOption(event.target.value)
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Returns Within</label>
                      <input
                        value={ReturnsWithin}
                        onChange={(event) =>
                          setReturnsWithin(event.target.value)
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">
                        Returns Accepted Option
                      </label>
                      <input
                        value={ReturnsAcceptedOption}
                        onChange={(event) =>
                          setReturnsAcceptedOption(event.target.value)
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Shipping Time Min</label>
                      <input
                        value={ShippingTimeMin}
                        onChange={(event) =>
                          setShippingTimeMin(event.target.value)
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Shipping Time Max</label>
                      <input
                        value={ShippingTimeMax}
                        onChange={(event) =>
                          setShippingTimeMax(event.target.value)
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Dispatch Time Max</label>
                      <input
                        value={DispatchTimeMax}
                        onChange={(event) =>
                          setDispatchTimeMax(event.target.value)
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">Sales Tax Percent</label>
                      <input
                        value={SalesTaxPercent}
                        onChange={(event) =>
                          setSalesTaxPercent(event.target.value)
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="form-outline">
                      <label className="form-label">
                        Shipping Service Cost
                      </label>
                      <input
                        value={ShippingServiceCost}
                        onChange={(event) =>
                          setShippingServiceCost(event.target.value)
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                {isloading ? (
                  <div className="mt-5 text-center">
                    <CircularProgress size={26} />
                  </div>
                ) : (
                  <button
                    type="submit"
                    onClick={(event) => {
                      event.preventDefault();
                      addproductshoptodb();
                    }}
                    className="w-50 btn btn-primary btn-block my-4 float-end"
                  >
                    Place order
                  </button>
                )}
              </div>
            </form>
          </React.Fragment>
        </div>
      </div>
    </>
  );
}
