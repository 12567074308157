import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Button,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { db, auth } from "../../firebase";
import { toast } from "react-toastify";
// components
import { Link } from "react-router-dom";
// import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from "@material-ui/icons/Delete";
import PageTitle from "../../components/PageTitle";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import {
  deleteDoc,
  getDocs,
  doc,
  query,
  collection,
  where,
} from "firebase/firestore";

export default function Shops() {
  const [data, setData] = useState([]);
  const [datatableData, setDatatableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [deleteid, setDeleteid] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handledelete = async (e) => {
    e.preventDefault();
    setOpen(false);
    const docRef = doc(db, "products", deleteid);
    deleteDoc(docRef)
      .then((res) => {
        notify("Products Deleted successfully!");
        setOpen(false);
        setData([]);
      })
      .catch((err) => {
        setOpen(false);
      });
  };

  const notify = (mgs) =>
    toast(mgs, {
      position: "top-right",
      theme: "light",
      type: "success",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  React.useEffect(() => {
    try {
      const q = query(
        collection(db, "products"),
        where("uid", "==", auth?.currentUser?.uid),
      );
      getDocs(q).then((res) => {
        const data = res.docs.map((item) => {
          var data = {
            Id: item.id,
            Name: item.data().Title,
            Price: item.data().StartPrice,
            Quantity: item.data().Quantity,
            SKU: item.data().SKU,
            Status: item.data().status,
          };

          return data;
        });
        console.log(data);
        setDatatableData(data);
      });
    } catch (err) {}
  }, [data]); //datatableData

  const columns = [
    {
      name: "Id",
      options: {
        filter: true,
      },
    },
    {
      name: "Name",
      options: {
        filter: false,
      },
    },

    {
      name: "Price",
      options: {
        filter: true,
      },
    },

    {
      name: "Quantity",
      options: {
        filter: false,
      },
    },
    {
      name: "SKU",
      options: {
        filter: true,
      },
    },
    {
      name: "Status",
      options: {
        filter: true,
      },
    },
    {
      name: "Action",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          const { rowData } = tableMeta;
          var link2 = `/app/productdraft/${rowData[0]}`;
          return (
            <>
              <Button className="mx-2">
                <DeleteIcon
                  onClick={() => {
                    handleClickOpen();
                    setDeleteid(rowData[0]);
                  }}
                />
              </Button>

              <Button className="mx-2">
                <Link to={link2}>
                  <OpenInNewIcon onClick={() => {}} />
                </Link>
              </Button>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <PageTitle title="Products" />

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Product?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete Product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handledelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <MUIDataTable
          title="Products List"
          data={datatableData}
          columns={columns}
          options={{
            selectableRows: "none",
            sort: true,
          }}
        />
      </Grid>
    </>
  );
}
