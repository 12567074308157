import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  CardContent,
  Card,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import PageTitle from "../../components/PageTitle";
import { toast } from "react-toastify";
import { API_URL } from "../../config";
import { useSelector } from "react-redux";
import { auth, db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
const packageFormatIdentifierlist = [
  "undefined",
  "letter",
  "largeLetter",
  "smallParcel",
  "mediumParcel",
  "parcel",
  "documents",
];
export default function DispatchOrder(props) {
  const [isloading, setIsloading] = useState(false);
  const { shop } = useSelector((state) => state.selectshoptoken);
  const [seletecOrder, setSeletecOrder] = useState({});
  const [pakageformat, setPakageformat] = useState("");
  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [depth, setDepth] = useState(0);
  const [unitvalue, setUnitvalue] = useState(0);
  const [unitweightingram, setUnitweightingram] = useState(0);

  React.useEffect(() => {
    const { access_token } = shop;
    const getsingleproduct = async () => {
      var data = JSON.stringify({
        token: access_token,
        orderid: props.match.params.id,
      });

      var config = {
        method: "post",
        url: `${API_URL}/getorderdetails`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          const { data } = response.data;
          const {
            orderId,
            creationDate,
            orderPaymentStatus,
            orderFulfillmentStatus,
            sellerId,
            buyer,
            pricingSummary,
            salesRecordReference,
            fulfillmentStartInstructions,
            totalFeeBasisAmount,
            lineItems,
          } = data;
          const { username } = buyer;

          const { priceSubtotal, deliveryCost, total } = pricingSummary;
          const {
            maxEstimatedDeliveryDate,
            shippingStep,
            minEstimatedDeliveryDate,
          } = fulfillmentStartInstructions[0];
          const { shipTo, shippingCarrierCode } = shippingStep;
          const { fullName, primaryPhone, contactAddress, email } = shipTo;
          const { phoneNumber } = primaryPhone;
          const {
            addressLine1,
            addressLine2,
            city,
            stateOrProvince,
            postalCode,
            countryCode,
          } = contactAddress;
          const { value } = totalFeeBasisAmount;

          var neworderobject = {
            orderId: orderId,
            creationDate: creationDate,
            orderPaymentStatus: orderPaymentStatus,
            orderFulfillmentStatus: orderFulfillmentStatus,
            sellerId: sellerId,
            buyer_name: username,
            priceSubtotal: priceSubtotal,
            deliveryCost: deliveryCost,
            total: total,
            salesRecordReference: salesRecordReference,
            deliveryDate: maxEstimatedDeliveryDate,
            totalFeeBasisAmount: value,
            phoneNumber: phoneNumber,
            shippingCarrierCode: shippingCarrierCode,
            minEstimatedDeliveryDate: minEstimatedDeliveryDate,
            city: city,
            postalCode: postalCode,
            CityStateProvince: `${stateOrProvince} ${countryCode}`,
            stateOrProvince: stateOrProvince,
            countryCode: countryCode,
            PostcodeCountryRegion: `${postalCode} ${addressLine1} ${addressLine2} UK`,
            itemlist: lineItems,
            fullName: fullName,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            email: email,
          };
          setSeletecOrder(neworderobject);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    getsingleproduct();
  }, [props.match.params.id, shop]);

  const dispatchedordersipper = async () => {
    setIsloading(true);
    var itemlist = seletecOrder.itemlist.map((item) => {
      const { title, quantity } = item;
      var data = {
        name: title,
        // "SKU": sku,
        quantity: quantity,
        customsDescription: "",
        unitValue: unitvalue,
        unitWeightInGrams: unitweightingram,
        extendedCustomsDescription: "",
        customsCode: "",
        customsDeclarationCategory: "none",
        requiresExportLicence: true,
      };
      return data;
    });
    var data = {
      items: [
        {
          orderReference: seletecOrder.orderId,
          recipient: {
            address: {
              fullName: seletecOrder.fullName,
              companyName: "",
              addressLine1: seletecOrder.addressLine1,
              addressLine2: seletecOrder.addressLine2,
              addressLine3: "",
              city: seletecOrder.city,
              county: "",
              postcode: seletecOrder.postalCode,
              countryCode: seletecOrder.countryCode,
            },
            phoneNumber: seletecOrder.phoneNumber,
            emailAddress: seletecOrder.email,
            addressBookReference: "",
          },
          sender: {
            tradingName: seletecOrder.sellerId,
            phoneNumber: "",
            emailAddress: "iftikhar.ismail@icloud.com",
          },
          billing: {
            address: {
              fullName: seletecOrder.fullName,
              companyName: "",
              addressLine1: seletecOrder.addressLine1,
              addressLine2: seletecOrder.addressLine2,
              addressLine3: "",
              city: seletecOrder.city,
              county: "",
              postcode: seletecOrder.postalCode,
              countryCode: seletecOrder.countryCode,
            },
            phoneNumber: seletecOrder.phoneNumber,
            emailAddress: seletecOrder.email,
          },
          packages: [
            {
              weightInGrams: weight,
              packageFormatIdentifier: pakageformat,
              customPackageFormatIdentifier: "",
              dimensions: {
                heightInMms: height,
                widthInMms: width,
                depthInMms: depth,
              },
              contents: itemlist,
            },
          ],
          orderDate: seletecOrder.creationDate,
          specialInstructions: "string",
          subtotal: seletecOrder.priceSubtotal.value,
          shippingCostCharged: seletecOrder.deliveryCost.value,
          otherCosts: 0,
          total: seletecOrder.total.value,
          currencyCode: "GBP",
          postageDetails: {
            sendNotificationsTo: "sender",
            consequentialLoss: 0,
            receiveEmailNotification: true,
            receiveSmsNotification: true,
            guaranteedSaturdayDelivery: true,
            requestSignatureUponDelivery: true,
            isLocalCollect: true,
            department: "string",
            AIRNumber: "string",
            IOSSNumber: "string",
            requiresExportLicense: true,
            commercialInvoiceNumber: "001",
            commercialInvoiceDate: "2022-06-29T14:15:22Z",
          },
          label: {
            includeLabelInResponse: true,
            includeCN: true,
            includeReturnsLabel: true,
          },
        },
      ],
    };

    const token = "Bearer ac43aeb6-1697-4ecd-844d-459c8defeac0";
    axios
      .post(
        `${API_URL}/createorders`,
        {
          data,
          token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(async (res) => {
        const { createdOrders } = res.data;
        if (createdOrders.length !== 0) {
          toast.success("Order Dispatch successfully");
          const {
            orderIdentifier,
            orderReference,
            createdOn,
            orderDate,
          } = createdOrders[0];

          await addDoc(collection(db, "royal_mailOrders"), {
            uid: auth.currentUser.uid,
            orderIdentifier: orderIdentifier,
            orderReference: orderReference,
            createdOn: createdOn,
            orderDate: orderDate,
            label: false,
          }).then(function () {
            toast.success("Order Save successfully");
            setIsloading(false);
          });
        } else {
          setIsloading(false);
          const { failedOrders } = res.data;
          const { errors } = failedOrders[0];
          const { errorMessage } = errors[0];
          toast.error(errorMessage.toString());
        }
      })
      .catch((err) => {
        setIsloading(false);
        toast.error("something went wroung");
      });
  };

  return (
    <>
      <PageTitle title="Dispatch New Order" />
      <div className="row">
        <Box>
          <Card>
            <div className="row">
              <div className="col-8">
                <Box>
                  <div className="m-2">
                    <h3>Order Numbers {seletecOrder.orderId}</h3>
                  </div>

                  <div className="row m-2">
                    <Box>
                      <Card>
                        <CardContent>
                          <div>
                            <h6>
                              Estimated delivery date shown to buyer:{" "}
                              {seletecOrder.deliveryDate}
                            </h6>
                          </div>
                        </CardContent>
                      </Card>
                    </Box>
                  </div>
                  <br />
                  <br />
                  <div className="row m-2">
                    <div className="col-4">
                      <Box>
                        <Card>
                          <CardContent>
                            <Typography variant="h3">
                              Purchase details
                            </Typography>
                            <div className="row">
                              <div className="col-4">
                                {" "}
                                <h6>Buyer</h6>
                              </div>
                              <div className="col-8">
                                <p>{seletecOrder.buyer_name}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <h6>Date sold</h6>{" "}
                              </div>
                              <div className="col-8">
                                {" "}
                                <p>{seletecOrder.creationDate}</p>{" "}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                {" "}
                                <h6>Date buyer paid</h6>{" "}
                              </div>
                              <div className="col-8">
                                {" "}
                                <p>{seletecOrder.creationDate}</p>{" "}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                {" "}
                                <h6>Sales number</h6>{" "}
                              </div>
                              <div className="col-8">
                                {" "}
                                <p>{seletecOrder.salesRecordReference}</p>{" "}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-4">
                                {" "}
                                <h6>Order total</h6>{" "}
                              </div>
                              <div className="col-8">
                                {" "}
                                <p>£ {seletecOrder.totalFeeBasisAmount}</p>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-4">
                                {" "}
                                <h6>Phone</h6>{" "}
                              </div>
                              <div className="col-8">
                                <p>{seletecOrder.phoneNumber}</p>{" "}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Box>
                    </div>
                    <div className="col-8">
                      <Box>
                        <Card>
                          <CardContent>
                            <Typography variant="h3">
                              Delivery details
                            </Typography>

                            <div className="row">
                              <div className="col-3">
                                {" "}
                                <h6>Delivery service</h6>
                              </div>
                              <div className="col-9">
                                <p>{seletecOrder.shippingCarrierCode}</p>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-3">
                                {" "}
                                <h6>Date dispatched</h6>
                              </div>
                              <div className="col-9">
                                <p>{seletecOrder.minEstimatedDeliveryDate}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3">
                                {" "}
                                <h6>Post to Street</h6>
                              </div>
                              <div className="col-9">
                                <p>{seletecOrder.postalCode}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3">
                                {" "}
                                <h6>City</h6>
                              </div>
                              <div className="col-9">
                                <p>{seletecOrder.city}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3">
                                {" "}
                                <h6>City/State/Province</h6>
                              </div>
                              <div className="col-9">
                                <p>{seletecOrder.CityStateProvince}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3">
                                {" "}
                                <h6>Country/Region</h6>
                              </div>
                              <div className="col-9">
                                <p>{seletecOrder.PostcodeCountryRegion}</p>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Box>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row m-2">
                    <Typography variant="h3" className="text-center">
                      Items
                    </Typography>
                    <Box>
                      <Card>
                        <CardContent>
                          {seletecOrder.itemlist !== undefined ? (
                            <>
                              {seletecOrder.itemlist.map(
                                (iteminlist, index) => {
                                  const {
                                    title,
                                    quantity,
                                    lineItemCost,
                                  } = iteminlist;
                                  const { value } = lineItemCost;
                                  return (
                                    <Box key={index}>
                                      <Card>
                                        <Typography
                                          variant="h4"
                                          className="mx-3 "
                                        >
                                          Item # {seletecOrder.itemlist.length}
                                        </Typography>
                                        <CardContent>
                                          <div className="row">
                                            <div className="col-3">
                                              {" "}
                                              <h6>Item Name</h6>
                                            </div>
                                            <div className="col-9">
                                              <p>{title}</p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-3">
                                              {" "}
                                              <h6>Quantity</h6>
                                            </div>
                                            <div className="col-9">
                                              <p>{quantity}</p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-3">
                                              {" "}
                                              <h6>Sold for</h6>
                                            </div>
                                            <div className="col-9">
                                              <p>£ {value}</p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-3">
                                              {" "}
                                              <h6>Total</h6>
                                            </div>
                                            <div className="col-9">
                                              <p>£ {value}</p>
                                            </div>
                                          </div>
                                        </CardContent>
                                      </Card>
                                      <br />
                                    </Box>
                                  );
                                },
                              )}
                            </>
                          ) : null}
                        </CardContent>
                      </Card>
                    </Box>
                  </div>
                </Box>
              </div>
              <div className="col-4 my-5">
                <div className="form-outline mx-3">
                  <label className="form-label">Package Format </label>
                  <select
                    onChange={(event) => {
                      setPakageformat(event.target.value);
                    }}
                    className="form-select form-select-sm"
                    aria-label=".form-select-sm"
                  >
                    {packageFormatIdentifierlist.map((key, index) => {
                      return (
                        <option value={key} key={index.toString()}>
                          {key}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-outline mx-3 my-2">
                  <label className="form-label">Weight (gm) </label>
                  <input
                    value={weight}
                    onChange={(event) => setWeight(event.target.value)}
                    type="number"
                    className="form-control"
                    min="1"
                    max="30000"
                  />
                  <div className="row py-3">
                    <div className="col-4 ">
                      <label>Height (mm)</label>
                      <input
                        value={height}
                        onChange={(event) => setHeight(event.target.value)}
                        type="number"
                        className="form-control"
                        min="1"
                        max="30000"
                      />
                    </div>
                    <div className="col-4">
                      <label>Width (mm)</label>
                      <input
                        value={width}
                        onChange={(event) => setWidth(event.target.value)}
                        type="number"
                        className="form-control"
                        min="1"
                        max="30000"
                      />
                    </div>
                    <div className="col-4">
                      <label>Depth (mm)</label>
                      <input
                        value={depth}
                        onChange={(event) => setDepth(event.target.value)}
                        type="number"
                        className="form-control"
                        min="1"
                        max="30000"
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-outline mx-1 my-2">
                  <div className="col-6">
                    <label>unitValue</label>
                    <input
                      value={unitvalue}
                      onChange={(event) => setUnitvalue(event.target.value)}
                      type="number"
                      className="form-control"
                      min="1"
                      max="30000"
                    />
                  </div>
                  <div className="col-6">
                    <label>unitWeightInGrams</label>
                    <input
                      value={unitweightingram}
                      onChange={(event) =>
                        setUnitweightingram(event.target.value)
                      }
                      type="number"
                      className="form-control"
                      min="1"
                      max="30000"
                    />
                  </div>
                </div>

                {isloading ? (
                  <div className="mt-5 text-center">
                    <CircularProgress size={26} />
                  </div>
                ) : (
                  <div className="my-5 float-end mx-3">
                    <button
                      onClick={() => {
                        dispatchedordersipper();
                      }}
                      type="button"
                      className="btn btn-success"
                    >
                      Dispatch Order
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </Box>
      </div>
    </>
  );
}
