import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { useDispatch } from "react-redux";
const PC = require("./parentcategories.json");
const allcategories = require("./allcategories.json");

function EbayCategories() {
  const dispatch = useDispatch();
  const [parentCategories, setParentCategories] = useState(PC);
  const [AllCategories, setAllCategories] = useState(allcategories);
  const [AllChildCategories, setAllChildCategories] = useState([]);
  const [ParentId, setParentId] = useState(null);
  const [childId, setchildId] = useState(null);
  const [LeadCategoies, setLeadCategoies] = useState([]);
  useEffect(() => {
    console.log("child", AllChildCategories);
    getchildbyparentid();
  }, [ParentId]);

  const getchildbyparentid = () => {
    let temp = [];
    setLeadCategoies([]);
    AllCategories.forEach((element) => {
      if (element.CategoryParentID === ParentId) {
        temp.push(element);
      }
      setAllChildCategories(temp);
    });
  };

  useEffect(() => {
    getleafchildrean();
  }, [childId]);
  const getleafchildrean = () => {
    let temp = [];
    AllCategories.forEach((element) => {
      if (element.CategoryParentID === childId) {
        temp.push(element);
      }
      setLeadCategoies(temp);
    });
  };

  return (
    <div>
      <Card sx={{ maxWidth: 345 }}>
        <div className="row">
          <div className="col-4 p-3">
            <div className="card" style={{ height: 300 }}>
              <div className="d-flex flex-column overflow-scroll  scoll-pane">
                {parentCategories.map((categories, index) => {
                  return (
                    <div className="ps-3" key={index.toString()}>
                      <span
                        onClick={() => {
                          setParentId(categories.CategoryID);
                        }}
                        className="p-2 fs-6"
                      >
                        {categories.CategoryName}
                        {" >"}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-4 p-3">
            <div className="card" style={{ height: 300 }}>
              <div className="d-flex flex-column overflow-scroll  scoll-pane">
                {AllChildCategories.map((categories, index) => {
                  return (
                    <div className="ps-3" key={index.toString()}>
                      <span
                        onClick={() => {
                          setchildId(categories.CategoryID);
                        }}
                        className="p-2 fs-6"
                      >
                        {categories.CategoryName}
                        {" >"}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-4 p-3">
            <div className="card" style={{ height: 300 }}>
              <div className="d-flex flex-column overflow-scroll  scoll-pane">
                {LeadCategoies.map((categories, index) => {
                  return (
                    <div className="ps-3" key={index.toString()}>
                      <span
                        onClick={() => {
                          dispatch({
                            type: "SelectCetogories",
                            payload: categories,
                          });
                        }}
                        className="p-2 fs-6"
                      >
                        {categories.CategoryName}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default EbayCategories;
