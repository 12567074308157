import React,{useState,useEffect} from "react";
import PageTitle from "../../components/PageTitle";
import { auth, db } from '../../firebase';
import {
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { CircularProgress } from "@material-ui/core";
import { toast } from 'react-toastify';
export default function Profile() {

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [Isloading, setIsloading] = useState(false);

  useEffect(() => {
    
    const getcurrentuserdata = async () => {

      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
   
        setUsername(docSnap.data().name);
        setEmail(docSnap.data().email);
        setPhonenumber(docSnap.data().phoneNumber);
      } 
    }

    getcurrentuserdata();
  
  
  }, [])

  const notify = (mgs,type) => toast(mgs, {
    
    position: "top-right",
    theme:'light',
    type:type,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  

  const updatecurrentuserprofile = async () => {
    setIsloading(true);
    

    try {
      const docRef = doc(db, "users", auth.currentUser.uid);
      await updateDoc(docRef, {
        name: username,
        email: email,
        phoneNumber:phonenumber
      }).then(function () {
        setIsloading(false);
        notify('Profile Updated successfully!','success')

      });
    } catch (err) {
      setIsloading(false);
 
    }

  }

  return (
    <>
      <PageTitle title="Profile" />

      <div className="container rounded bg-white mt-5 mb-5">
        <div className="row">
          <div className="col-md-3 border-right">
            <div className="d-flex flex-column align-items-center text-center p-3 py-5"><img className="rounded-circle mt-5" width="150px"
             src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" 
             alt="profile"
             /><span className="font-weight-bold">{username}</span><span className="text-black-50">{email}</span><span> </span></div>
          </div>
          <div className="col-md-5 border-right">
            <div className="p-3 py-5">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="text-right">Profile Settings</h4>
              </div>
              <div className="col-md-12">
                <label className="labels">Name</label>
                <input  type="text" className="form-control"  onChange={(event)=>setUsername(event.target.value)}  placeholder="first name" value={username} />
                </div>
              <br/>
              <div className="col-md-12"><
                label className="labels">Email ID</label>
                <input type="text" className="form-control" onChange={(event)=>setEmail(event.target.value)}  placeholder="enter email id" value={email} />
                </div>
              <br/>
                <div className="col-md-12">
                  <label className="labels">Mobile Number</label>
                  <input type="text" className="form-control" onChange={(event)=>setPhonenumber(event.target.value)}  placeholder="enter phone number" value={phonenumber} />
                  </div>
              
              
            
              {
                Isloading?
                <div className="mt-5 text-center">
                <CircularProgress size={26} />
                </div>
                :
                <div className="mt-5 text-center">
                <button className="btn btn-primary profile-button" onClick={()=>{
                  updatecurrentuserprofile()
                }} type="button">Save Profile</button>
                  </div>
              }
              
            </div>
          </div>
        
        </div>
      </div>






    </>
  );

}

