import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Button,
  Tooltip,
  Modal,
  Box,
  Card,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { db, auth } from "../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PageTitle from "../../components/PageTitle";
import {
  deleteDoc,
  getDocs,
  doc,
  query,
  collection,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";

export default function Shipper() {
  const [datatableData, setDatatableData] = useState([]);
  const [data, setData] = useState();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [deleteid, setDeleteid] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleCloseModel = () => setOpenModel(false);
  const [modalStyle] = useState(getModalStyle);
  const [openmodel, setOpenModel] = React.useState(false);
  const [isedit, setisedit] = useState(false);

  const [code, setCode] = useState("");
  const [api, setApi] = useState("");
  const [shippername, setShippername] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [edituid, setEdituid] = useState("");

  const integrateroyalmail = async () => {
    try {
      if (code !== "" && api !== "" && shippername !== "") {
        setIsloading(true);
        await addDoc(collection(db, "shipper"), {
          uid: auth.currentUser.uid,
          api_key: code,
          api_url: api,
          name: shippername,
        }).then(function () {
          setIsloading(false);
          toast.success("Royal Mail Integration successfull!");
          setOpenModel(false);
        });
      } else {
        toast.error("Mandatory Field cannot be empty!.");
      }
    } catch (err) {
      setIsloading(false);
      setOpenModel(false);
    }
  };

  const saveintegrateroyalmail = async () => {
    setIsloading(true);
    try {
      const docRef = doc(db, "shipper", edituid);
      await updateDoc(docRef, {
        api_key: code,
        api_url: api,
        name: shippername,
      }).then(() => {
        toast.success("Shop Updated successfully");
        setIsloading(false);
        setOpenModel(false);
        setisedit(false);
        setData([]);
      });
    } catch (err) {
      setIsloading(false);
      setOpenModel(false);
      setisedit(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handledelete = async (e) => {
    e.preventDefault();
    setOpen(false);
    const docRef = doc(db, "shipper", deleteid);
    deleteDoc(docRef)
      .then((res) => {
        notify("Shipper Deleted successfully!");
        setData([]);
        setOpen(false);
      })
      .catch((err) => {
        setOpen(false);
      });
  };

  const notify = (mgs) =>
    toast(mgs, {
      position: "top-right",
      theme: "light",
      type: "success",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  React.useEffect(() => {
    try {
      const q = query(
        collection(db, "shipper"),
        where("uid", "==", auth?.currentUser?.uid),
      );
      getDocs(q).then((res) => {
        const data = res.docs.map((item) => {
          var data = {
            id: item.id,
            ShipperName: item.data().name,
            API: item.data().api_url,
            Code: item.data().api_key,
          };

          return data;
        });
        setDatatableData(data);
      });
    } catch (err) {}
  }, [data]);

  const columns = [
    {
      name: "id",
    },
    {
      name: "ShipperName",
      options: {
        filter: false,
      },
    },

    {
      name: "API",
      options: {
        filter: true,
      },
    },

    {
      name: "Code",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;

          return (
            <>
              <Tooltip title="Edit">
                <EditIcon
                  onClick={() => {
                    setShippername(rowData[1]);
                    setApi(rowData[2]);
                    setCode(rowData[3]);
                    setEdituid(rowData[0]);
                    setisedit(true);
                    setOpenModel(true);
                  }}
                  style={{ color: "blue" }}
                  className="mx-2"
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Button className="mx-2">
                  <DeleteIcon
                    onClick={() => {
                      handleClickOpen();
                      setDeleteid(rowData[0]);
                    }}
                  />
                </Button>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: 800,
      height: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: "none",
    },
  }));
  const classes = useStyles();
  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 55 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  return (
    <>
      <div>
        <button
          type="button"
          onClick={() => setOpenModel(true)}
          className="btn btn-success float-end"
        >
          Integrate Royal Mail
        </button>
        <PageTitle title="Shipper Details" />
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Shipper?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete shipper?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handledelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openmodel}
        onClose={handleCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={modalStyle} className={classes.paper}>
          <div>
            <h3>Royal Mail Intgration </h3>
          </div>

          <div className="row">
            <Box className={classes.root}>
              <Card>
                <CardContent>
                  <div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        value={shippername}
                        onChange={(event) => setShippername(event.target.value)}
                        class="form-control"
                        id="floatingInput"
                        placeholder="Shipper Name"
                      />
                      <label>Shipper Name</label>
                    </div>
                    <br />
                    <div className="form-floating">
                      <input
                        type="text"
                        value={api}
                        onChange={(event) => setApi(event.target.value)}
                        class="form-control"
                        id="floatingInput"
                        placeholder="API"
                      />
                      <label>API</label>
                    </div>
                    <br />
                    <div className="form-floating">
                      <input
                        type="text"
                        value={code}
                        onChange={(event) => setCode(event.target.value)}
                        class="form-control"
                        id="floatingInput"
                        placeholder="Code"
                      />
                      <label>Code</label>
                    </div>
                    {isedit ? (
                      <>
                        {isloading ? (
                          <div className="mt-5 text-center">
                            <CircularProgress size={26} />
                          </div>
                        ) : (
                          <button
                            type="primary"
                            onClick={() => {
                              saveintegrateroyalmail();
                            }}
                            className="btn btn-success float-end my-3"
                          >
                            Save
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {isloading ? (
                          <div className="mt-5 text-center">
                            <CircularProgress size={26} />
                          </div>
                        ) : (
                          <button
                            type="primary"
                            onClick={() => {
                              integrateroyalmail();
                            }}
                            className="btn btn-success float-end my-3"
                          >
                            Add
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Box>
          </div>
          <br />
          <br />
        </Box>
      </Modal>
      <Grid item xs={12}>
        <MUIDataTable
          title="Shipper List"
          data={datatableData}
          columns={columns}
          options={{
            selectableRows: "none",
            sort: true,
          }}
        />
      </Grid>
    </>
  );
}
