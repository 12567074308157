import React, { useState, useEffect } from "react";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { API_URL } from "../../config";
import { useSelector } from "react-redux";
// styles
import axios from "axios";
import PageTitle from "../../components/PageTitle";
import MUIDataTable from "mui-datatables";
import moment from "moment";

export default function Dashboard(props) {
  const [originalData, setOriginalData] = useState([]);
  const calender = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [ordersdata, setordersdata] = useState([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ]);
  const [graphData, setGraphData] = useState([]);
  const [data, setdata] = useState([]);
  const [checkboxValue, setCheckboxValue] = useState("");
  const { shop } = useSelector((state) => state.selectshoptoken);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  useEffect(() => {
    try {
      const { access_token } = shop;
      axios
        .post(`${API_URL}/getorders`, {
          token: access_token,
          limit: "200",
          offset: "0",
          ordertype: "shipped",
        })
        .then((response) => {
          setordersdata([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
          const { data } = response;
          const { orders } = data.data;
          const shippeddata = orders.map((item) => {
            const {
              orderId,
              creationDate,
              orderPaymentStatus,
              orderFulfillmentStatus,
              sellerId,
              buyer,
              pricingSummary,
              salesRecordReference,
              fulfillmentStartInstructions,
              totalFeeBasisAmount,
              lineItems,
            } = item;
            const { username } = buyer;
            const { priceSubtotal, deliveryCost, total } = pricingSummary;
            const {
              maxEstimatedDeliveryDate,
              shippingStep,
              minEstimatedDeliveryDate,
            } = fulfillmentStartInstructions[0];
            const { shipTo, shippingCarrierCode } = shippingStep;
            const { primaryPhone, contactAddress } = shipTo;
            const { phoneNumber } = primaryPhone;
            const {
              addressLine1,
              addressLine2,
              city,
              stateOrProvince,
              postalCode,
              countryCode,
            } = contactAddress;
            const { value } = totalFeeBasisAmount;

            var data = {
              orderId: orderId,
              creationDate: creationDate,
              orderPaymentStatus: orderPaymentStatus,
              orderFulfillmentStatus: orderFulfillmentStatus,
              sellerId: sellerId,
              buyer_name: username,
              priceSubtotal: priceSubtotal,
              deliveryCost: deliveryCost,
              total: total,
              salesRecordReference: salesRecordReference,
              deliveryDate: maxEstimatedDeliveryDate,
              totalFeeBasisAmount: value,
              phoneNumber: phoneNumber,
              shippingCarrierCode: shippingCarrierCode,
              minEstimatedDeliveryDate: minEstimatedDeliveryDate,
              city: city,
              postalCode: postalCode,
              CityStateProvince: `${stateOrProvince} ${countryCode}`,
              PostcodeCountryRegion: `${postalCode} ${addressLine1} ${addressLine2} UK`,
              itemlist: lineItems,
            };
            addgraphdata(creationDate);
            return data;
          });

          console.log(data);
          setdata(shippeddata);
          setOriginalData(shippeddata);
          let buildgraphdata = [];
          for (let index = 0; index < ordersdata.length; index++) {
            let orderValue = ordersdata[index + 1];
            let calenderName = calender[index + 1];
            buildgraphdata.push({
              name: calenderName,
              Orders: orderValue,
            });
          }
          setGraphData(buildgraphdata);
        })
        .catch(function (error) {
          console.log(error.response.status);
        });
    } catch (error) {
      console.log(error);
    }
  }, [shop]);

  const dateCompare = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    if (date1 > date2) {
      return -1;
    } else if (date1 < date2) {
      return 1;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    try {
      let date;
      let filterdata = [];
      switch (checkboxValue) {
        case "Today":
          date = moment().utc().format("MM/DD/YYYY");
          originalData.forEach((item) => {
            let creationDate = moment(item.creationDate)
              .utc()
              .format("MM/DD/YYYY");
            if (date === creationDate) {
              filterdata.push(item);
            }
          });
          setdata(filterdata);
          break;
        case "Weekly":
          date = moment().subtract(7, "days").format("MM/DD/YYYY");
          originalData.forEach((item) => {
            let creationDate = moment(item.creationDate)
              .utc()
              .format("MM/DD/YYYY");
            let result = dateCompare(date, creationDate);
            if (result === 1) {
              filterdata.push(item);
            }
          });
          setdata(filterdata);
          break;
        case "Monthy":
          date = moment().subtract(30, "days").format("MM/DD/YYYY");
          originalData.forEach((item) => {
            let creationDate = moment(item.creationDate)
              .utc()
              .format("MM/DD/YYYY");
            let result = dateCompare(date, creationDate);
            if (result === 1) {
              filterdata.push(item);
            }
          });
          setdata(filterdata);
          break;
        case "Yearly":
          date = moment().subtract(365, "days").format("MM/DD/YYYY");
          originalData.forEach((item) => {
            let creationDate = moment(item.creationDate)
              .utc()
              .format("MM/DD/YYYY");
            let result = dateCompare(date, creationDate);
            if (result === 1) {
              filterdata.push(item);
            }
          });
          setdata(filterdata);
          break;
        case "All":
          setdata(originalData);
          break;
        default:
          setdata(originalData);
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }, [checkboxValue]);

  const columns = [
    {
      name: "orderId",
      options: {
        filter: false,
      },
    },
    {
      name: "sellerId",
      options: {
        filter: false,
      },
    },
    {
      name: "creationDate",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <p>{moment(value).utc().format("MM/DD/YYYY")}</p>
            </>
          );
        },
      },
    },

    {
      name: "buyer_name",
      options: {
        filter: false,
      },
    },

    {
      name: "orderPaymentStatus",
      options: {
        filter: false,
      },
    },
    {
      name: "orderFulfillmentStatus",
      options: {
        filter: false,
      },
    },
  ];

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const addgraphdata = (date) => {
    const newdate = new Date(date);
    switch (newdate.getMonth() + 1) {
      case 1:
        ordersdata[0]++;
        break;
      case 2:
        ordersdata[1]++;
        break;
      case 3:
        ordersdata[2]++;
        break;
      case 4:
        ordersdata[3]++;
        break;
      case 5:
        ordersdata[4]++;
        break;
      case 6:
        ordersdata[5]++;
        break;
      case 7:
        ordersdata[6]++;
        break;
      case 8:
        ordersdata[7]++;
        break;
      case 9:
        ordersdata[8]++;
        break;
      case 10:
        ordersdata[9]++;
        break;
      case 11:
        ordersdata[10]++;
        break;
      case 12:
        ordersdata[11]++;
        break;

      default:
        break;
    }
  };

  return (
    <>
      <PageTitle title="Dashboard" />
      <div>
        <span className="fs-5 float-end">
          Total Orders <span className="fs-2">{data.length}</span>
        </span>
      </div>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <BarChart
            width={screenSize.dynamicWidth * 0.8}
            height={400}
            data={graphData}
            barSize={30}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Orders" fill="#d66c20" />
          </BarChart>
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              value="Today"
              checked={checkboxValue === "Today" ? true : false}
              onChange={(event) => setCheckboxValue(event.target.value)}
            />
          }
          label="Today"
        />
        <FormControlLabel
          control={
            <Checkbox
              value="Weekly"
              checked={checkboxValue === "Weekly" ? true : false}
              onChange={(event) => setCheckboxValue(event.target.value)}
            />
          }
          label="Weekly"
        />
        <FormControlLabel
          control={
            <Checkbox
              value="Monthy"
              checked={checkboxValue === "Monthy" ? true : false}
              onChange={(event) => setCheckboxValue(event.target.value)}
            />
          }
          label="Monthly"
        />
        <FormControlLabel
          control={
            <Checkbox
              value="Yearly"
              checked={checkboxValue === "Yearly" ? true : false}
              onChange={(event) => setCheckboxValue(event.target.value)}
            />
          }
          label="Yearly"
        />
        <FormControlLabel
          control={
            <Checkbox
              value="All"
              checked={checkboxValue === "All" ? true : false}
              onChange={(event) => setCheckboxValue(event.target.value)}
            />
          }
          label="All"
        />
        <Grid item xs={12}>
          <MUIDataTable
            title="Shops List"
            data={data}
            columns={columns}
            options={{
              sort: true,
              selectableRows: "none",
              rowsPerPage: 10,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
