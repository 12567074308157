import React, { useState } from "react";
import axios from 'axios';
import {
    Box,
    CardContent,
    Card,
    Button,
    CircularProgress
} from "@material-ui/core";
import { db, auth } from '../../firebase';
import PageTitle from "../../components/PageTitle";
import DeleteIcon from '@material-ui/icons/Delete';
import {
    doc,
    getDoc,
    getDocs,
    query,
    collection,
    where
} from "firebase/firestore";
import {API_URL} from '../../config'; 

export default function ProductDraft(props) {

    const [productobject, setProductobject] = useState({})
    const [shoplist, setShoplist] = useState([]);
    const [selectpq, setSelectpq] = useState('');
    const [productpublishlist, setProductpublishlist] = useState([])
    const [selecttedshop, setselecttedshop] = useState({});
    const [isloading, setIsloading] = useState(false);

    React.useEffect(() => { }, [productpublishlist])
    React.useEffect(() => {
        try {

            const q = query(collection(db, "shops"), where("uid", "==", auth?.currentUser?.uid));
            getDocs(q).then((res) => {
                const data = res.docs.map(item => {

                    var data = {
                        "id": item.id,
                        "clientSecret": item.data().clientSecret,
                        "clinetId": item.data().clinetId,
                        "plateform": item.data().plateform,
                        "shopName": item.data().shopName,
                        "status": item.data().status,
                        "uid": item.data().uid,
                        "access_token": item.data().access_token,
                        "refresh_token": item.data().refresh_token,
                        "productionOAuth": item.data().productionOAuth
                    }

                    return data;
                })
  
                setShoplist(data)
            });


        } catch (err) {
            console.error(err.message);
        }

    }, [])

    React.useEffect(() => {


        const getsingleproduct = async () => {
            const docRef = doc(db, "products", props.match.params.id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setProductobject(docSnap.data())
                setSelectpq(docSnap.data().Quantity);
            } 
        }

        getsingleproduct();

    }, [props.match.params.id])


    const assignproduct = () => {
        if (Object.keys(selecttedshop).length === 0) {
            alert('select shop!')
            return;
        }
        if (!selectpq) {
            alert('select quantity!')
            return;
        }

        const newlist = [...productpublishlist];
        newlist.push({
            shopName: selecttedshop.shopName,
            Quantity: selectpq,
            access_token: selecttedshop.access_token
        });
        setProductpublishlist(newlist);
        setselecttedshop({})

    }


    const publishproduct = async() => {

        for (let index = 0; index < productpublishlist.length; index++) {
            setIsloading(true)
            productobject.token = productpublishlist[index].access_token;
            productobject.Quantity = productpublishlist[index].Quantity;
            axios.post(`${API_URL}/user/addlisting`, productobject).then((res) => {
                setIsloading(false)
            }).catch((err) => {
                console.log(err);
            });

        }


    }
    return (
        <>
            <PageTitle title="Products Draft" />
            <div className='row'>
                <Box>
                    <Card>
                        <div className="row">
                            <div className="col-8">

                                <CardContent>

                                    <div className='row'>
                                        <div className='col-3'> <h6>Title</h6></div>
                                        <div className='col-9'><p>{productobject.Title}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>Description</h6></div>
                                        <div className='col-9'><p>{productobject.Description}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>CategoryID</h6></div>
                                        <div className='col-9'><p>{productobject.CategoryID}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>CategoryName</h6></div>
                                        <div className='col-9'><p>{productobject.CategoryName}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ConditionID</h6></div>
                                        <div className='col-9'><p>{productobject.ConditionID}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>StartPrice</h6></div>
                                        <div className='col-9'><p>{productobject.StartPrice}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>Country</h6></div>
                                        <div className='col-9'><p>{productobject.Country}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>Currency</h6></div>
                                        <div className='col-9'><p>{productobject.Currency}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>DispatchTimeMax</h6></div>
                                        <div className='col-9'><p>{productobject.DispatchTimeMax}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>Location</h6></div>
                                        <div className='col-9'><p>{productobject.Location}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>SKU</h6></div>
                                        <div className='col-9'><p>{productobject.SKU}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>Brand</h6></div>
                                        <div className='col-9'><p>{productobject.Brand}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>PostalCode</h6></div>
                                        <div className='col-9'><p>{productobject.PostalCode}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>Type</h6></div>
                                        <div className='col-9'><p>{productobject.Type}</p></div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-3'> <h6>ReturnsWithinOption</h6></div>
                                        <div className='col-9'><p>{productobject.ReturnsWithinOption}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ReturnsWithin</h6></div>
                                        <div className='col-9'><p>{productobject.ReturnsWithin}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ReturnsAcceptedOption</h6></div>
                                        <div className='col-9'><p>{productobject.ReturnsAcceptedOption}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ShippingIncludedInTax</h6></div>
                                        <div className='col-9'><p>{productobject.ShippingIncludedInTax}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ApplyShippingDiscount</h6></div>
                                        <div className='col-9'><p>{productobject.ApplyShippingDiscount}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>SalesTaxPercent</h6></div>
                                        <div className='col-9'><p>{productobject.SalesTaxPercent}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ShippingService</h6></div>
                                        <div className='col-9'><p>{productobject.ShippingService}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ShippingServiceCost</h6></div>
                                        <div className='col-9'><p>{productobject.ShippingServiceCost}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ShippingServiceAdditionalCost</h6></div>
                                        <div className='col-9'><p>{productobject.ShippingServiceAdditionalCost}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ShippingServicePriority</h6></div>
                                        <div className='col-9'><p>{productobject.ShippingServicePriority}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ExpeditedService</h6></div>
                                        <div className='col-9'><p>{productobject.ExpeditedService}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ShippingTimeMin</h6></div>
                                        <div className='col-9'><p>{productobject.ShippingTimeMin}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>ShippingTimeMax</h6></div>
                                        <div className='col-9'><p>{productobject.ShippingTimeMax}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>FreeShipping</h6></div>
                                        <div className='col-9'><p>{productobject.FreeShipping}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'> <h6>Site</h6></div>
                                        <div className='col-9'><p>{productobject.Site}</p></div>
                                    </div>


                                    <div>

                                    </div>


                                </CardContent>
                            </div>
                            <div className="col-4">


                                <div className='row my-5'>
                                    <div className='col-3'> <h3>Quantity</h3></div>
                                    <div className='col-9'><h3>{productobject.Quantity}</h3></div>
                                </div>
                                <div className='row my-5'>
                                    <div className='col-3'> <h3>Status</h3></div>
                                    <div className='col-9'><h3>{productobject.status}</h3></div>
                                </div>

                                <div className="form-outline mx-3">
                                    <label className="form-label">Select Shop</label>
                                    <select
                                        onChange={
                                            (event) => {
                                                const { access_token, shopName } = shoplist[event.target.value];

                                                setselecttedshop({
                                                    access_token,
                                                    shopName
                                                })
                                            }
                                        }
                                        placeholder='Select Shop' className="form-select form-select-sm" aria-label=".form-select-sm">
                                        {
                                            shoplist.map((key, index) => {

                                                return (

                                                    <option value={index} key={index.toString()}>{key.shopName}</option>

                                                )

                                            })
                                        }
                                    </select>

                                </div>

                                <div className="form-outline mx-3">
                                    <label className="form-label">Select Product Quantity</label>
                                    <input
                                        value={selectpq}
                                        onChange={(event) => setSelectpq(event.target.value)}
                                        type="number" className="form-control"
                                    />
                                    <div className="my-3">
                                        <button
                                            onClick={() => {
                                                assignproduct()
                                            }}
                                            type="button" className="btn btn-primary">Assign Quantity</button>
                                    </div>
                                </div>

                                <div className="mx-3">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">ShopName</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                productpublishlist.map((key, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{key.shopName}</td>
                                                            <td>{key.Quantity}</td>
                                                            <td>
                                                                <Button
                                                                    onClick={() => {
                                                                        setProductpublishlist(
                                                                            [
                                                                                ...productpublishlist.slice(0, index),
                                                                                ...productpublishlist.slice(index + 1)
                                                                            ]
                                                                        )
                                                                    }}
                                                                >
                                                                    <DeleteIcon style={{ color: 'red' }} />
                                                                </Button>
                                                            </td>
                                                        </tr>



                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                {
                                    isloading ?
                                        <div className="mt-5 text-center">
                                            <CircularProgress size={26} />
                                        </div> :
                                        <div className="my-5 float-end mx-3">
                                            <button
                                                onClick={() => {
                                                    publishproduct()
                                                }}
                                                type="button" className="btn btn-success">Publish Product</button>
                                        </div>
                                }




                            </div>
                        </div>


                    </Card>
                </Box>
            </div>
        </>
    );

}

