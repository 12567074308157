import React, { useState, useEffect } from "react";

import {
  Grid,
  TextField,
  CircularProgress,
  Button,

} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PageTitle from "../../components/PageTitle";
import { toast } from 'react-toastify';
import { auth, db } from '../../firebase';
import {
  collection,
  addDoc,
} from "firebase/firestore";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

export default function AddSupplier() {
  const classes = useStyles();

  const [companyName, setCompanyName] = useState('');
  const [category, setCategory] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [isloading, setIsloading] = useState(false);


  const addSupplierFirebase = async () => {
   

    try {

      if (companyName !== '' && category !== ''
        && contactPerson !== '' && number !== ''
        && email !== '' && country !== ''
        && addressLine1 !== '' && addressLine2 !== ''
        && city !== '' && postcode !== '') {
          
        setIsloading(true);

        await addDoc(collection(db, "suppliers"), {
          uid: auth.currentUser.uid,
          companyName: companyName,
          category: category,
          contactPerson: contactPerson,
          number: number,
          email: email,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          country: country,
          city: city,
          postcode: postcode,

        }).then(function () {
          setIsloading(false);
          toast.success('Supplier Updated successfully');
          setCompanyName('');
          setCategory('');
          setContactPerson('');
          setNumber('');
          setEmail('');
          setAddressLine1('');
          setAddressLine2('');
          setCountry('');
          setCity('');
          setPostcode('');
        });
      } else {
        toast.error('Mandatory Field cannot be empty!.');

      }

    } catch (err) {
      setIsloading(false);
    }

  }



  return (
    <>
      <PageTitle title="Add Supplier" />
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>


            <Grid container spacing={4}>
              <Grid item xs={6} >
                <TextField required id="filled-basic" label="Company Name"
                  placeholder="Company Name"
                  onChange={(event) => {
                    setCompanyName(event.target.value)
                  }}
                  value={companyName}
                  variant="filled" fullWidth />
              </Grid>
              <Grid item xs={6} >
                <TextField required id="filled-basic" label="Category"
                  placeholder="Category"
                  onChange={(event) => {
                    setCategory(event.target.value)
                  }}
                  value={category}
                  variant="filled" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="filled-basic" label="Contact Person"
                  placeholder="Contact Person"
                  onChange={(event) => {
                    setContactPerson(event.target.value)
                  }}
                  value={contactPerson}
                  variant="filled" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="filled-basic" label="Phone Number"
                  placeholder="Phone Number"
                  onChange={(event) => {
                    setNumber(event.target.value)
                  }}
                  value={number}
                  variant="filled" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="filled-basic" label="Email Address"
                  placeholder="Email Address"
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  value={email}
                  variant="filled" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="filled-basic" label="Address line 1"
                  placeholder="Address"
                  onChange={(event) => {
                    setAddressLine1(event.target.value)
                  }}
                  value={addressLine1}
                  variant="filled" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="filled-basic" label="Address line 2"
                  placeholder="Address"
                  onChange={(event) => {
                    setAddressLine2(event.target.value)
                  }}
                  value={addressLine2}
                  variant="filled" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="filled-basic" label="City"
                  placeholder="City"
                  onChange={(event) => {
                    setCity(event.target.value)
                  }}
                  value={city}
                  variant="filled" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="filled-basic" label="Post Code"
                  placeholder="Post Code"
                  onChange={(event) => {
                    setPostcode(event.target.value)
                  }}
                  value={postcode}
                  variant="filled" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField required id="filled-basic" label="Country"
                  placeholder="Country"
                  onChange={(event) => {
                    setCountry(event.target.value)
                  }}
                  value={country}
                  variant="filled" fullWidth />
              </Grid>

              <Grid item xs={6}>
                <div className={classes.creatingButtonContainer}>
                  {isloading ? (
                    <div className="mt-5 text-center">
                      <CircularProgress size={26} />
                    </div>
                  ) : (
                    <Button
                      onClick={addSupplierFirebase}
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.createAccountButton}
                    >
                      Add Supplier
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>

              </Grid>
            </Grid>
          </React.Fragment>
        </div>

      </div>
    </>
  );

}

