import React, { useState } from "react";
import {
  Grid,
  Tooltip,
  Card,
  CardContent,
  Modal,
  useMediaQuery,
  useTheme,
  Button,
  CircularProgress,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { db, auth } from "../../firebase";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
// components
import { Link } from "react-router-dom";
import { API_URL } from "../../../src/config";
import axios from "axios";
import sendEmailToSuppierByEmail from "../../../src/productEmail";
// import EditIcon from '@material-ui/icons/Edit';
import StrollerIcon from "@material-ui/icons/LocalShippingSharp";
import DeleteIcon from "@material-ui/icons/Delete";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  deleteDoc,
  getDocs,
  doc,
  query,
  collection,
  where,
} from "firebase/firestore";
export default function StockList() {
  const [data, setData] = useState([]);
  const [datatableData, setDatatableData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [deleteid, setDeleteid] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isloading, setIsloading] = useState(false);
  const [email, setEmail] = useState("");
  const [productsku, setProductsku] = useState("");
  const [productname, setProductname] = useState("");
  const [productQuantity, setProductQuantity] = useState(0);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handledelete = async (e) => {
    e.preventDefault();
    setOpen(false);
    const docRef = doc(db, "products", deleteid);
    deleteDoc(docRef)
      .then((res) => {
        notify("Products Deleted successfully!", "success");
        setOpen(false);
        setData([]);
      })
      .catch((err) => {
        setOpen(false);
      });
  };

  const notify = (mgs, type) =>
    toast(mgs, {
      position: "top-right",
      theme: "light",
      type: type,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  React.useEffect(() => {
    try {
      const q = query(
        collection(db, "products"),
        where("uid", "==", auth?.currentUser?.uid),
      );
      getDocs(q).then((res) => {
        const data = res.docs.map((item) => {
          var data = {
            Id: item.id,
            Name: item.data().Title,
            Price: item.data().StartPrice,
            Quantity: item.data().Quantity,
            SKU: item.data().SKU,
            Status: item.data().status,
          };

          return data;
        });
        setDatatableData(data);
      });

      getsupplierlisr();
    } catch (err) {}
  }, [data]); //datatableData

  const getsupplierlisr = () => {
    const q = query(
      collection(db, "suppliers"),
      where("uid", "==", auth?.currentUser?.uid),
    );
    getDocs(q).then((res) => {
      const data = res.docs.map((item) => {
        var data = {
          id: item.id,
          Name: item.data().companyName,
          Category: item.data().category,
          Number: item.data().number,
          Email: item.data().email,
          Postcode: item.data().postcode,
        };
        return data;
      });
      setSupplierData(data);
    });
  };

  const columns = [
    {
      name: "Id",
      options: {
        filter: true,
      },
    },
    {
      name: "Name",
      options: {
        filter: false,
      },
    },

    {
      name: "Price",
      options: {
        filter: true,
      },
    },

    {
      name: "Quantity",
      options: {
        filter: false,
      },
    },
    {
      name: "SKU",
      options: {
        filter: true,
      },
    },

    {
      name: "Action",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          const { rowData } = tableMeta;
          setProductname(rowData[1]);
          setProductsku(rowData[4]);
          let color = rowData[3] > 3 ? "green" : "red";
          return (
            <>
              <Tooltip title="Place Order">
                <Button className="mx-2">
                  <StrollerIcon
                    style={{ color: color }}
                    onClick={() => {
                      handleClickOpen();
                      setDeleteid(rowData[0]);
                    }}
                  />
                </Button>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: "70%",
      height: "70%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: "none",
      overflowY: "scroll",
    },
  }));
  const classes = useStyles();
  const modelstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    boxShadow: 24,
    p: 4,
  };

  const sendEmailToSuppier = () => {
    setIsloading(true);
    if (email === "") {
      setIsloading(false);
      notify("Please Select Supplier", "info");
      return;
    }
    if (productQuantity === 0) {
      setIsloading(false);
      notify("Please Select Product Quantity", "info");
      return;
    }

    axios
      .post(`${API_URL}/sendmail`, {
        body: sendEmailToSuppierByEmail(),
        email: email,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsloading(false);
          notify("Order has been been sent successfully", "success");
        }
      })
      .catch((err) => {
        setIsloading(false);
        notify("Something went wroung!!", "error");
      });
  };

  return (
    <>
      <h1>Stock List</h1>
      <>
        <Modal
          onClose={handleClose}
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card style={modelstyle} className={classes.paper}>
            <CardContent></CardContent>
            <h5 className="form-label  my-2">Select Supplier</h5>
            <select
              onChange={(event) => {
                const { Email } = supplierData[event.target.value];
                setEmail(Email);
              }}
              placeholder="Select Supplier"
              className="form-select form-select-sm w-100"
              aria-label=".form-select-sm"
            >
              {supplierData.map((key, index) => {
                return (
                  <option value={index} key={index.toString()}>
                    {key.Name}
                  </option>
                );
              })}
            </select>
            <div className="form-outline">
              <h5 className="form-label my-2">Email</h5>
              <input
                value={email}
                type="text"
                className="form-control"
                disabled
              />
            </div>

            <div className="row mb-2">
              <div className="col-6">
                <div className="form-outline">
                  <h5 className="form-label  my-2">Product Name</h5>
                  <input
                    value={productname}
                    type="text"
                    className="form-control"
                    disabled
                  />
                </div>
              </div>

              <div className="col-6">
                <h5 className="form-label  my-2">Product SKU</h5>
                <div className="input-group ">
                  <input
                    value={productsku}
                    type="text"
                    className="form-control"
                    aria-describedby="addon-wrapping"
                    disabled
                  />
                </div>
              </div>
            </div>

            <h5>Select Quantity</h5>
            <select
              onChange={(event) => {
                setProductQuantity(event.target.value);
              }}
              value={productQuantity}
              placeholder="Select Supplier"
              className="form-select form-select-sm w-100"
              aria-label=".form-select-sm"
            >
              {[
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
              ].map((key, index) => {
                return (
                  <option value={key} key={index.toString()}>
                    {key}
                  </option>
                );
              })}
            </select>

            {isloading ? (
              <div className="mt-5 text-center">
                <CircularProgress size={26} />
              </div>
            ) : (
              <div className="float-end py-5">
                <Button autoFocus className="py-1" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    sendEmailToSuppier();
                  }}
                  className="py-1"
                  autoFocus
                >
                  Order
                </Button>
              </div>
            )}
          </Card>
        </Modal>

        <Grid item xs={12}>
          <MUIDataTable
            title="Products List"
            data={datatableData}
            columns={columns}
            options={{
              selectableRows: "none",
              sort: true,
            }}
          />
        </Grid>
      </>
      <footer className="bg-light pb-5">
        <div className="container text-center">
          <p className="font-italic text-muted mb-0">
            &copy; Copyrights Company.com All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}
