import React from "react";



export default function RoyalMailOrder() {


  const myFunction = () => {
    window.open("https://auth.parcel.royalmail.com", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=800,height=800");
  }

  return (
    <>
      <div>

        <button
          type="button" className="btn btn-success"
          onClick={() => myFunction()}
        >
          Open Royal Mail
        </button>
      </div>


    </>
  );

}

