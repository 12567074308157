import React, { useState, useEffect } from "react";

import {
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Button,

} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toast } from 'react-toastify';
import { auth, db } from '../../firebase';
import {
  collection,
  addDoc,
} from "firebase/firestore";
import ebay from '../../images/ebay.png';
import amazon from '../../images/amazon.png';
import woocommerce from '../../images/woocommerce.png';

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

const currencies = [
  {
    value: 'Amazon',
    image_url: amazon,
    label: 'Amazon',
  },
  {
    value: 'EBay',
    image_url: ebay,
    label: 'EBay',
  },
  {
    value: 'WooCommerce',
    image_url: woocommerce,
    label: 'WooCommerce',
  },

];

export default function AddShop() {
  const classes = useStyles();

  const [shopName, setshopName] = useState('');
  const [ebayshopname, setEbayshopname] = useState('');
  const [clinetId, setClinetId] = useState('Iftikhar-oneclic-PRD-03a3d0a07-40921ebf');
  const [clientSecret, setClientSecret] = useState('PRD-3a3d0a07e658-ef78-4284-a0ad-0dd2');
  const [plateform, setplateform] = React.useState('Amazon');
  const [productionOAuth, setProductionOAuth] = useState('https://auth.ebay.com/oauth2/authorize?client_id=Iftikhar-oneclic-PRD-03a3d0a07-40921ebf&response_type=code&redirect_uri=ONECLIC_limited-Iftikhar-onecli-bxbxp&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly');
  const [devId, setDevId] = useState('bdc3a06d-6353-4cf6-8b9c-53e4ab153642');
  const [isloading, setIsloading] = useState(false);


  const addshoptodbebay = async () => {
    try {

      if (ebayshopname !== '' && plateform !== '') {
        setIsloading(true);
        await addDoc(collection(db, "shops"), {
          uid: auth.currentUser.uid,
          shopName: ebayshopname,
          clinetId: clinetId,
          clientSecret: clientSecret,
          plateform: plateform,
          productionOAuth: productionOAuth,
          devId: devId,
          status: 'pending',
          'access_token': '',
          'refresh_token': ''
        }).then(function () {
          setIsloading(false);
          toast.success('Shop Updated successfully');
          setshopName('');
          setClinetId('');
          setClientSecret('');
          setProductionOAuth('');
          setDevId('');
        });
      } else {
        toast.error('Mandatory Field cannot be empty!.');
      }


    } catch (err) {
      setIsloading(false);

    }

  }

  const addshoptodbamazon = () => {
    alert('amazon')
  }

  const addshoptodbwoocommerce = () => {
    alert('woocommerce')
  }


  return (
    <>

      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>


            <Grid container spacing={4}>

              <Grid item xs={3} ></Grid>
              <Grid item xs={6} className="float-center" >
                <br /><br />
                <h2 className='text-center'>Add Shops</h2>
                <br /><br />
                <TextField required
                  id="plateforn name"
                  select
                  label="Select Platform"
                  value={plateform}
                  onChange={(event) => {
                    setplateform(event.target.value)
                  }}
                  variant="filled" fullWidth
                  helperText="Select Platform"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>

                      <div>
                        <div className="float-end mx-2">  {option.label}</div>
                        <div className="float-start mx-2"><img src={option.image_url} alt={option.value} width={80} height={40} /></div>
                      </div>
                    </MenuItem>
                  ))}
                </TextField>
                {
                  plateform === 'Amazon' ?
                    <div>
                      <br />
                      <TextField required label="Amazon Shop Name"
                        placeholder="Amazon Shop Name"
                        // onChange={(event) => {
                        //   setshopName(event.target.value)
                        // }}
                        // value={shopName}
                        variant="filled" fullWidth />
                      <br />
                      <br />
                      <div className={classes.creatingButtonContainer}>
                        {isloading ? (
                          <div className="mt-5 text-center">
                            <CircularProgress size={26} />
                          </div>
                        ) : (
                          <Button
                            onClick={addshoptodbamazon}
                            size="large"
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.createAccountButton}
                          >
                            Add Shop
                          </Button>
                        )}
                      </div>
                    </div>
                    :
                    plateform === 'EBay' ?
                      <div>
                        <br />
                        <TextField required label="Ebay Shop Name"
                          placeholder="Ebay Shop Name"
                          key='ebayshopname'
                          onChange={(event) => {
                            setEbayshopname(event.target.value)
                          }}
                          value={ebayshopname}
                          variant="filled" fullWidth />
                        <br />
                        <br />

                        <div className={classes.creatingButtonContainer}>
                          {isloading ? (
                            <div className="mt-5 text-center">
                              <CircularProgress size={26} />
                            </div>
                          ) : (
                            <Button
                              onClick={() => addshoptodbebay()}
                              size="large"
                              variant="contained"
                              color="primary"
                              fullWidth
                              className={classes.createAccountButton}
                            >
                              Add Shop to EBay
                            </Button>
                          )}
                        </div>
                      </div>

                      :
                      <div>
                        <br />
                        <TextField required label="WooCommerce Shop Name"
                          placeholder="WooCommerce Shop Name"
                          // onChange={(event) => {
                          //   setshopName(event.target.value)
                          // }}
                          // value={shopName}
                          variant="filled" fullWidth />
                        <br />
                        <br />

                        <div className={classes.creatingButtonContainer}>
                          {isloading ? (
                            <div className="mt-5 text-center">
                              <CircularProgress size={26} />
                            </div>
                          ) : (
                            <Button
                              onClick={addshoptodbwoocommerce}
                              size="large"
                              variant="contained"
                              color="primary"
                              fullWidth
                              className={classes.createAccountButton}
                            >
                              Add Shop
                            </Button>
                          )}
                        </div>
                      </div>
                }
              </Grid>
              <Grid item xs={3} ></Grid>

            </Grid>







          </React.Fragment>
        </div>

      </div>
    </>
  );




}








